import { Injectable } from "@angular/core";
import { Apollo, gql } from "apollo-angular";
import { DocumentNode } from "graphql";
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { environment } from '../../../environments/environment'
@Injectable({
  providedIn: 'root',
})
export class LibraryService {

  rates: any[];
  loading = true;
  error: any;

  library = {};

  options = {
    headers: new HttpHeaders({
      Authorization: 'Basic ' + btoa(`${environment.user}:${environment.pass}`)
    })
  }

  constructor(
    private apollo: Apollo,
    private http: HttpClient
  ) { }

  getLibraryJson() {
    return new Promise<any>( (resolve, reject) =>{
      this.http.get(`${environment.urlApiJson}/ecomicro-api/node/biblioteca`, this.options).toPromise()
      .then( res => { resolve(res); },
      msg => reject(msg));
    });
  }

  // getLibrary(searchFilter: any, secondUse: boolean, language: string, limit, offset, callback) {
  //   this.apollo.query<any>({
  //     query: this.getLibraryQuery(searchFilter, secondUse, limit, offset, language),
  //     fetchPolicy: 'no-cache',
  //     context: {
  //       headers: {
  //         Authorization: 'Basic ' +
  //           btoa('Gojko._Vaso_234Irvine6423:rKpwMEd@6$Z%%Xgs#uF^UtnptHRS-_mfu2x$tawsb$GGQq8Kg3J&r&R_c?Krq$&9V3XyDr=Q@*-g$!77'),
  //       },
  //     }
  //   }).subscribe(
  //     (result) => { callback(result.data.nodeQuery.entities, result.data.nodeQuery.count); },
  //     (error) => { console.log(error) }
  //   )
  // }


  // //Queda pendiente para validar los campos
  // private getLibraryQuery(filter: any, secondUse: boolean, limit, offset, language: string): DocumentNode {
  //   let filterQuery = '';
  //   if (filter) {
  //     if (filter.description && secondUse === false) {
  //       filterQuery += `{
  //         operator: LIKE
  //         field: "title"
  //         value: "%${filter.description}%"
  //       },`
  //     }

  //     if (filter.description && secondUse === true) {
  //       filterQuery += `{
  //         operator: LIKE
  //         field: "field_descripcion_library.summary"
  //         value: "%${filter.description}%"
  //       },`
  //     }

  //     if (filter.country) {
  //       filterQuery += `{
  //         operator: LIKE
  //         field: "field_pais_articulo.entity.title"
  //         value: "${filter.country}"
  //       },`
  //     }

  //     if (filter.topic) {
  //       filterQuery += `{
  //         operator: LIKE
  //         field: "field_tipo_proyecto"
  //         value: "${filter.topic}"
  //       },`
  //     }

  //     if (filter.uuid) {
  //       filterQuery += `{
  //         operator: EQUAL
  //         field: "uuid"
  //         value: "${filter.uuid}"
  //       }`;
  //     }
  //   }

  //   return gql`query biblioteca {
  //         nodeQuery(limit: 100
  //           filter: {
  //             conditions: [{
  //               operator: EQUAL
  //               field: "status"
  //               value: "1"
  //               language: ${language}
  //             },
  //               {
  //                 operator: EQUAL
  //                 field: "type"
  //                 value: "Biblioteca"
  //               },${filterQuery}
  //             ]
  //           }
  //         ) {
  //           count
  //           entities {
  //             ... on NodeBiblioteca {
  //               title
  //               uuid
  //               fieldTipoProyecto
  //               fieldPaisArticulo {
  //                 entity {
  //                   ... on NodePaises {
  //                     title
  //                     fieldBandera {
  //                       url
  //                     }
  //                   }
  //                 }
  //               }
  //               fieldDescripcionLibrary {
  //                 summary
  //                 processed
  //               }
  //               fieldImagenLibrary {
  //                 url
  //               }
  //               fieldFechaLibrary {
  //                 value
  //                 date
  //               }
  //               fieldPdfFile {
  //                 entity {
  //                   uri {
  //                     url
  //                   }
  //                 }
  //               }
  //             }
  //           }
  //         }
  //       }`;
  // }

  getLibraryView() {
    return this.library;
  };

  setLibraryView(item: object) {
    this.library = item;
  };

}
