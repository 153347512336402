<div class="wrapper banner-home">
  <div class="page-header background-news">
    <div class="filter"></div>
    <div class="content-center">
      <div class="container">

        <div class="d-flex justify-content-between flex-wrap align-content-center">
          <div class="col-12 col-sm-12 col-md-6 col-lg-6 h-100">
            <div class="title-brand mt-5 mb-3">
              <h1 class="banner-title">
                {{'news' | translate}}
              </h1>
            </div>
          </div>
          <div class="col-12 col-sm-12 col-md-6 col-lg-6 h-100">
            <div class="title-brand-description mt-4">
              <h3 class="description mt-4 text-center">
                <span>
                  {{'financiamientoclimatico' | translate}}
                </span>
              </h3>
              <h5>
                {{'financiamientoclimaticoNews' | translate}}
              </h5>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>
<!-- Barra de suscripción -->
<div class="suscripcion">
  <div class="container suscripcion__content">
    <div class="row">
      <div class="col-12 col-sm-12 col-md-6 col-lg-6">
        <p class="suscripcion__content--title">
          {{'suscribeBulletin' | translate}}
        </p>
      </div>

      <div class="col-12 col-sm-12 col-md-6 col-lg-6">
        <form role="form" id="contact-form" method="post" [formGroup]="form" (ngSubmit)="SendProyect()" novalidate>
          <div class="row pt-2 pd-x">
            <div class="suscripcion__content--input">
              <div class="input-group-prepend">
                <img class="img-sobre" src="../../../assets/images/icono-sobre.png" alt="sobre">
                <input placeholder="{{'enterEmail' | translate}}" type="email" class="form-control suscripcion--input" name="email" formControlName="email">
              </div>
            </div>
            <button type="button" (click)="open(content)"  
            class="btn back-green suscripcion--button">{{'suscribirse' | translate}}</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<ng-container *ngIf="newsLetttersJson.length == undefined">
  <div class="spinner-container d-flex justify-content-center">
    <div class="spinner-border text-success" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
</ng-container>

<div class="col-12">
  <div class="row pt-5 mb-5 contenedor-sletter">

    <div class="card border-card contenedor-library" *ngFor="let sletter of newsLetttersJson" [routerLink]="['/bulletin', sletter.id]" data-aos="fade-up">
      <img [src]="sletter.previewImage" class="card-img-top dms-img box" alt="img-logo">
      <div class="card-body">
        <h5 class="card-title text-left titulo-card">{{sletter.title}}</h5>
        <p class="card-text descripcion-card text-left mt-1">{{sletter.summary}}</p>
      </div>
      <div class="card-footer">
        <hr class="line-card">
        <h6 class="card-text fecha-card text-right">{{sletter.ordenBoletin +"-"+ sletter.year}}</h6>
      </div>
    </div>

  </div>
</div>
<app-footer></app-footer>

<ng-template #content let-c="close" let-d="dismiss">
  <div class="modal-header">
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body-ecomicro">
    <p>{{'validateRecaptcha' | translate}}</p>

    <form role="form" id="contact-form" method="post" [formGroup]="form" (ngSubmit)="SendProyect()" novalidate>

      <ngx-recaptcha2 class="g-recaptcha mx-auto w-100" #captchaElem 
                      [siteKey]="siteKey" formControlName="recaptcha" [hl]="lang">
      </ngx-recaptcha2>

      <button type="submit" class="btn back-green mt-2" (click)="SendProyect()">{{'send'| translate}}</button>
    </form>
  </div>
</ng-template>
