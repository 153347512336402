<div class="contain-bar-white">
  <div class="container-fluid">
    <div class="row">
      <div class="col-xl-10 col-lg-10 col-md-10 col-sm-12">
        <img class="img-fluid dms-img" src="../../../../assets/images/logo-ecomicro.png" alt="">
        <img class="img-fluid dms-img" src="../../../../assets/images/logo-canada.png" alt="">
        <img class="img-fluid dms-img" src="../../../../assets/images/logo-ndf.png" alt="">
        <img class="img-fluid img-idb" src="../../../../assets/images/logo-fomin.png" alt="">
      </div>
    </div>
  </div>
</div>
