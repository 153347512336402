<footer class="footer">
  <div class="container-footer">
    <div class="d-flex flex-wrap align-content-center">
      <nav class="footer-nav">
        <div class="row marginl-5" *ngIf="width > 767">
          
          <div class="text-center">
            <img class="img-fluid img-footer" src="../../../../assets/images/canada-logo-white.png" alt="Canada">
          </div>
          <div class="text-center">
            <img class="img-fluid img-footer" src="../../../../assets/images/ndf-logo-white.png" alt="NDF">
          </div>
          <div class="text-center">
            <img class="img-fluid img-footer" src="../../../../assets/images/fomin-logo-white.png" alt="FOMIN">
          </div>
        </div>
        <div class="row marginl-5 d-flex flex-column" *ngIf="width <= 767">
          
          <div class="col-sm-12 text-center">
            <img class="img-fluid img-footer" src="../../../../assets/images/canada-logo-white.png" alt="Canada">
          </div>
          <div class="col-sm-12 text-center">
            <img class="img-fluid img-footer" src="../../../../assets/images/ndf-logo-white.png" alt="NDF">
          </div>
          <div class="col-sm-12 text-center">
            <img class="img-fluid img-footer" src="../../../../assets/images/fomin-logo-white.png" alt="FOMIN">
          </div>
        </div>
      </nav>
      <div class="credits">
        <a href="">
          <h4>
            {{'terminosycondiciones' | translate}}
          </h4>
        </a>
      </div>
    </div>
  </div>
</footer>
