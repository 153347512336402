import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatDate'
})
export class FormatDatePipe implements PipeTransform {

  transform(value: any, ...args: unknown[]): unknown {
    let newDate = new Date(value);
    let day = newDate.getDate();
    let month = (newDate.getMonth() + 1);
    let year = newDate.getFullYear();

    if (day <= 9 && month <= 9) {
      return `0${day}-0${month}-${year}`
    }

    if (day >= 9 && month <= 9) {
      return `${day}-0${month}-${year}`
    }

    if (day <= 9 && month >= 9) {
      return `0${day}-${month}-${year}`
    }

    return `${day}-${month}-${year}`;
  }

}
