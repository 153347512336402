<div class="wrapper banner-home">
  <div class="page-header section-dark">
    <div class="filter"></div>
    <div class="content-center">
      <div class="container">
        <div class="d-flex justify-content-between flex-wrap align-content-center">
          <div class="col-12 col-sm-12 col-md-6 col-lg-6 h-100">
            <div class="title-brand mt-5 mb-3">
              <h1 class="banner-title">
                {{'quienessomos' | translate}}
              </h1>
            </div>
          </div>
          <div class="col-12 col-sm-12 col-md-6 col-lg-6 h-100">
            <div class="title-brand-description mt-4">
              <h3 class="description mt-4 text-center">
                <span>
                  {{'financiamientoclimatico' | translate}}
                </span>
              </h3>
              <h5>
                {{'financiamientoclimaticotext' | translate}}
              </h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container-fluid">
  <div class="section-one-tex">
    <div class="section-tex" innerHTML="{{'quienessomostextuno' | translate}}">
      <div class="col-12">
        <div class="row cont-why-video">
          <iframe class="embed-responsive" width="430" height="270" src="https://www.youtube.com/embed/CscHPgftTaQ"
            frameborder="0" title="About video 1"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen>
          </iframe>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container-fluid back-gray">
  <div class="section-two-tex">
    <div class="row">
      <div class="col-12 col-md-6 d-flex">
        <div class="row w-100">
          <div class="col-12">
            <h2>
              {{'porqueecomicro' | translate}}
            </h2>
          </div>
          <div class="col-12 cont-why-video">
            <iframe class="embed-responsive" width="430" height="230" src="https://www.youtube.com/embed/XtSF54xnF0w"
              frameborder="0" title="About video 2"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen>
            </iframe>
          </div>
        </div>

      </div>
      <div class="col-12 col-md-6">
        <div class="sect-text" innerHTML="{{'porqueecomicrotext' | translate}}">
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container-fluid">
  <div class="section-three-tex">
    <div class="row">
      <div class="col-12 col-md-12">
        <div class="sectOneTest">
          <h2>
            {{'nuestroenfoque' | translate}}
          </h2>
        </div>
        <div class="sectTwoTest">
          <div class="sect-text" innerHTML="{{'nuestroenfoquetext' | translate}}">

          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container-fluid back-gray">
  <div class="section-four-tex">
    <div class="row">
      <div class="col-12 col-md-6">
        <div class="row">
          <div class="col-12">
            <h2>{{'finance_knowledge' | translate}}</h2>
          </div>
        </div>
        <div class="sect-text">
          <p>
            {{'finance_parrafo' | translate}}
          </p>
          <ul class="ul-style" innerHTML="{{'finance_items' | translate}}">

          </ul>
          <p>
            {{'finance_parrafo1' | translate}}
          </p>
          <p>
            {{'finance_parrafo2' | translate}}
          </p>
        </div>

      </div>
      <div class="col-12 col-md-6 d-flex">
        <div class="row w-100">
          <div class="col-12">
          </div>
          <div class="col-12 cont-why-video">
            <iframe class="embed-responsive" width="430" height="230" src="https://www.youtube.com/embed/Olx1XQBdfbw"
              frameborder="0" title="About video 3"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen>
            </iframe>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container-fluid">
  <div class="section-fifth-tex">
    <div class="row">
      <div class="col-12">
        <h2 class="text-center mt-3">{{'gender_perspective' | translate}}</h2>
        <div class="sect-text">
          <p>
            {{'gender_perspective_1' | translate}}
          </p>
          <p>
            {{'gender_perspective_2' | translate}}
          </p>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container-fluid back-gray">
  <div class="section-six-tex">
    <div class="row">
      <div class="col-12 col-md-12">
        <div class="sectOneTest">
          <h2>{{'donors' | translate}}</h2>
        </div>

        <div class="sect-text">
          <p>
            {{'donors_parrafo' | translate}}
          </p>

          <div class="section-donors d-flex flex-wrap align-content-center justify-content-center card" data-aos="fade-zoom-in"
          data-aos-easing="ease-in-back"
          data-aos-delay="300"
          data-aos-offset="0">
            <a [href]="donorsData[0].link" target="_blank">
              <div class="row">
                <div class="col-12 col-md-3 d-flex flex-wrap align-content-center justify-content-center">
                  <img class="img-fluid" [src]="donorsData[0].image" alt="">
                </div>
                <div class="col-12 col-md-9 section-description">
                  {{'descripcion_canada' | translate}}
                </div>
              </div>
            </a>
          </div>
          <div class="section-donors d-flex flex-wrap align-content-center justify-content-center card" data-aos="fade-zoom-in"
          data-aos-easing="ease-in-back"
          data-aos-delay="300"
          data-aos-offset="0">
            <a [href]="donorsData[1].link" target="_blank">
              <div class="row">
                <div class="col-12 col-md-3 d-flex flex-wrap align-content-center justify-content-center">
                  <img class="img-fluid" [src]="donorsData[1].image" alt="">
                </div>
                <div class="col-12 col-md-9 section-description">
                  {{'descripcion_ndf' | translate}}
                </div>
              </div>
            </a>
          </div>
          <div class="section-donors d-flex flex-wrap align-content-center justify-content-center card" data-aos="fade-zoom-in"
          data-aos-easing="ease-in-back"
          data-aos-delay="300"
          data-aos-offset="0">
            <a [href]="donorsData[2].link" target="_blank">
              <div class="row">
                <div class="col-12 col-md-3 d-flex flex-wrap align-content-center justify-content-center">
                  <img class="img-fluid" [src]="donorsData[2].image" alt="">
                </div>
                <div class="col-12 col-md-9 section-description">
                  {{'descripcion_bidlab' | translate}}
                </div>
              </div>
            </a>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>
