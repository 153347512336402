import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { DocumentNode } from 'graphql';
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { environment } from '../../../environments/environment'

@Injectable({
  providedIn: 'root',
})
export class HomeService {

  rates: any[];
  loading = true;
  error: any;
  language: string;
  options = {
    headers: new HttpHeaders({
      Authorization: 'Basic ' + btoa(`${environment.user}:${environment.pass}`)
    })
  }

  constructor(
    private apollo: Apollo,
    private http: HttpClient) {
  }

  getSliderHome() {
    return this.http.get(`${environment.urlApiJson}/ecomicro-api/node/slider_home`, this.options);
  }

  getBeneficiarios() {
    return this.http.get(`${environment.urlApiJson}/ecomicro-api/node/beneficiarios`, this.options);
  }
}
