import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { donorsData } from 'src/app/core/mocks/data-news';
import { SeoService } from 'src/app/core/services/seo.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AboutComponent implements OnInit {

  public donorsData: any[] = donorsData;

  constructor(
    private router: Router,
    private seoService: SeoService,
    private title: Title,
    ) {
    this.router.events.subscribe((event: any) => {
      if ((typeof event.url !== 'undefined') && event.url.includes('lang')) {
        this.ngOnInit();
      }
    });
   }

  ngOnInit(): void {
    this.flags();
  }

  flags() {
    this.seoService.generateFlags({
      title: 'Sobre Nosotros | Al trabajar con intermediarios financieros en la región',
      site_name: 'EcoMicro',
      description: 'Al trabajar con intermediarios financieros en la región, EcoMicro tiene como objetivo poner la energía renovable, la eficiencia energética y la adaptación al cambio climático al alcance de las micro, pequeñas y medianas empresas (MIPYMES)',
      slug: 'about',
      image: 'https://ecomicro.azureedge.net/banner-home.3ed2f47437daf300fe8b.png'
    });
    this.title.setTitle('Sobre Nosotros| Al trabajar con intermediarios financieros en la región');
  }

}
