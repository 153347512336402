import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-contador-animado',
  templateUrl: './contador-animado.component.html',
  styleUrls: ['./contador-animado.component.scss']
})
export class ContadorAnimadoComponent implements OnInit {

  valorInicial = 0;

  @Input('title') titulo: string = '';
  @Input('cantidadMax') cantMaxima: number = 0;
  @Input('estado') estado: boolean = false;

  constructor() { }

  ngOnInit(): void {    
    if (this.estado) {
      this.executeCount();
    }
  }

  executeCount() {
    setTimeout(() => {
      this.animateCount(this.cantMaxima);
    }, 500);
  }

  animateCount(cantidadMaxima: number) {
    this.valorInicial = 0;
    let tiempo = setInterval(() => {
      this.valorInicial += 1;
      if (this.valorInicial === cantidadMaxima) {
        clearInterval(tiempo);
      }
    }, 100)
  }
}
