<div class="col-12" *ngFor="let news of newsData">
  <div class="row">
    <img class="image-card-section" [src]="news.image" alt="">
  </div>
  <div class="row d-flex align-items-center">
    <div class="col-12 p-0 m-0">
      <h3 class="title-card-section pt-3 mb-0">{{news.title}}</h3>
    </div>
    <div class="col-12 p-0 m-0">
      <h3 class="category-card-section text-left">{{ news.description }}</h3>
    </div>
  </div>
  <div class="row">
    <div class="col-12 p-0 m-0">
      <p class="description-card-section" [innerHTML]=''></p>
    </div>
  </div>
</div>
