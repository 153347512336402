import { LibraryService } from 'src/app/core/services/library.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';

@Component({
  selector: 'app-view-library',
  templateUrl: './view-library.component.html',
  styleUrls: ['./view-library.component.scss']
})
export class ViewLibraryComponent implements OnInit {

  library: any;
  language = '';
  lang = 'en';
  system = { open: false };

  constructor(
    private route: ActivatedRoute,
    private libraryService: LibraryService, private router: Router) {
      this.lang = localStorage.getItem('lang');
      this.language = (this.lang === 'en') ? 'EN' : 'ES';
    }

    ngOnInit(): void {
      this.route.params
      .subscribe(
        (params: Params) => {
          if (params['id'] !== undefined) {
            const id = params['id'];
            const newObject = new Object();
            newObject['uuid'] = id;
            this.system.open = true;
          } else {
            this.system.open = true;
          }
      });
  }

}
