<div *ngIf="system.open">
  <div class="wrapper banner-home">
    <div class="page-header section-dark">
      <div class="filter"></div>
      <div class="content-center">
        <div class="container">

          <div class="d-flex justify-content-between flex-wrap align-content-center">
            <div class="col-12 col-sm-12 col-md-6 col-lg-6 h-100">
              <div class="title-brand mt-5 mb-3">
                <h1 class="banner-title">
                  {{'projects' | translate}}
                </h1>
              </div>
            </div>
            <div class="col-12 col-sm-12 col-md-6 col-lg-6 h-100">
              <div class="title-brand-description mt-4">
                <h3 class="description mt-4 text-center">
                  <span>
                    {{'financiamientoclimatico' | translate}}
                  </span>
                </h3>
                <h5>
                  {{'financiamientoclimaticoProjects' | translate}}
                </h5>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>

  <div class="container-project">
    <div class="initial-section">
      <div class="sect-title">
        <h2 class="title-green mb-5">{{projectsData.title}}</h2>
        <div class="d-flex justify-content-center align-content-center flex-wrap">
          <img class="img-fluid no-transition" [src]="projectsData.imageProject"
            alt="imagen proyecto">
        </div>
      </div>
    </div>
  </div>

  <div class="container-project">
    <div class="sect-project-content">
      <div class="row">
        <div class="col-lg-3 col-md-6 col-sm-12 col-12">
          <p class="typeProject">
            {{projectsData.projectType}}
          </p>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-12 col-12">
          <div class="pl-3 row">
            <img class="img-pais" [src]="projectsData.countryImage" alt="img-library">
            <p class="name-pais">{{projectsData.countryTitle}}</p>
          </div>
        </div>
      </div>
      <div class="sect-tex mt-3 mb-2" [innerHTML]="projectsData.descriptionProj.processed">
      </div>
    </div>
  </div>

  <div class="container-project" *ngIf="projectsData.projectVideo != null">
    <div class="col-12">
      <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 mx-auto">
          <div class="embed-container">
            <iframe class="mx-auto d-block p-1" [src]="projectsData.projectVideo.uri | DomSafe" frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen title="Project video 1"></iframe>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container-project-carousel">
    <app-card-projects [projectData]="projectsData" [viewInternal]="true"></app-card-projects>
  </div>

  <app-footer></app-footer>
</div>
