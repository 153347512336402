<div id="content-results" class="" infiniteScroll (scrolled)="footerCounter()">
  <!-- Banner -->
  <app-banner-slider></app-banner-slider>

  <!-- About Us -->
  <div class="container-fluid back-gray">
    <div class="section-two-text container-home">
      <div class="row">
        <div class="col-12">
          <h2 class="title-blue mb-3">{{'quienessomos' | translate}}</h2>
        </div>
        <div class="col-12 col-md-6">
          <div class="row ">
            <div class="col-12 cont-why-video">
              <iframe class="embed-responsive" width="430" height="230" src="https://www.youtube.com/embed/XtSF54xnF0w"
                frameborder="0" title="Home video 1"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen>
              </iframe>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="sect-text">
            <div innerHTML="{{'quienessomostext' | translate}}"></div>
          </div>
        </div>
        <div class="col-12 col-md-12 col-sm-12 mt-3">
          <div class="sect-text">
            <div innerHTML="{{'quienessomostextdos' | translate}}"></div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- 3 Areas -->
  <div class="page-header" style="background-image: url('../../../assets/imagenesEcomicro/fondo_areas_green.png');">
    <div class="section-tree-areas">
      <div class="row">
        <div class="col-lg-12 col-md-10 col-sm-10 col-10 mx-auto mt-5">
          <div class="col-sm-8 col-md-10 col-lg-12 col-xl-12 mx-auto text-center">
            <p class="titulo-section-green-areas">
              {{'tresfinanzasverdes' | translate}}
            </p>
          </div>
        </div>
        <div class="col-lg-12 col-md-12 col-sm-12 col-12 mt-3">
          <div class="col-lg-12 col-md-10 col-sm-10 col-10 mx-auto">
            <p class="text-white text-center">
              {{'asociarsefinancierasmipymes' | translate}}
            </p>
          </div>
        </div>
      </div>
      <div class="row d-flex justify-content-center margenes-img">
        <div class="col-sm-6 col-md-6 col-lg-4 mt-areas" data-aos="fade-bottom" data-aos-offset="200"
          data-aos-easing="ease-in-sine" data-aos-duration="400">
          <img src="../../../assets/images/iconos-ecomicro/grupo-5998.png" class="img-icon-ecomicro" alt="icono-img">
          <h5 class="text-center text-white">
            <div innerHTML="{{'facilitandoenergetica' | translate}}"></div>
          </h5>
        </div>
        <div class="col-sm-6 col-md-6 col-lg-4 mt-areas" data-aos="fade-bottom" data-aos-offset="200"
          data-aos-easing="ease-in-sine" data-aos-duration="400">
          <img src="../../../assets/images/iconos-ecomicro/grupo-5999.png" class="img-icon-ecomicro" alt="icono-img">
          <h5 class="text-center text-white">
            <div innerHTML="{{'promocionadaptacionclimatico' | translate}}"></div>
          </h5>
        </div>
        <div class="col-sm-6 col-md-6 col-lg-4 mt-areas" data-aos="fade-bottom" data-aos-offset="200"
          data-aos-easing="ease-in-sine" data-aos-duration="400">
          <img src="../../../assets/images/iconos-ecomicro/grupo-6079.png" class="img-icon-ecomicro" alt="icono-img">
          <h5 class="text-center text-white">
            <div innerHTML="{{'permiteaccesoenergia' | translate}}"></div>
          </h5>
        </div>
      </div>

    </div>

  </div>

  <!-- Projects -->
  <div class="container-fluid">
    <div class="section-two-text map-header container-home">
      <div class="row">
        <div class="col-12">
          <h2 class="title-green text-uppercase">{{'proyectos' | translate}}</h2>
        </div>
        <div class="col-12">
          <div class="sect-text">
            <div innerHTML="{{'projectostext' | translate}}"></div>
          </div>
        </div>
      </div>

      <div class="row justify-content-around content-map mt-4">
        <div class="col-12 col-md-6">
          <div id="mapInitiativesS" class="w-100 h-100 box-border"></div>
        </div>
      </div>
    </div>
  </div>

  <!-- Results to date -->
  <div class="container-fluid back-gray">
    <div class="section-two-text container-home">
      <div class="row">
        <div class="col-12 mb-3">
          <h2 class="title-blue">{{'resultadofecha' | translate}}</h2>
        </div>
        <div class="col-12">
          <div class="sect-text tex-center-p">
            <p>
              {{'resultadofechatext' | translate}}
            </p>
          </div>
        </div>
      </div>

      <div class="embed-responsive" id="container-frame">
        <iframe class="responsive-iframe" id="scaled-frame" title="powerBiIframe" src="https://app.powerbi.com/view?r=eyJrIjoiNzg3NTcyMjktYjg2MC00ZDRkLTk2MjgtNDg1ZTBmMmYzNDMxIiwidCI6IjlhOTM3M2YzLWI4ODktNDI3ZS05NjFmLTBlYjY3N2IxMzQzYyJ9&pageName=ReportSection" allowfullscreen></iframe>
      </div>

    </div>
  </div>


  <!-- Beneficiaries -->
  <div class="container-fluid">

    <div class="sect-beneficiaries container-home">
      <div class="sect-title">
        <h2 class="title-green">
          {{'beneficiarios' | translate}}
        </h2>
        <div class="text-title">
          <p>
            {{'beneficiarioshometext' | translate}}
          </p>
        </div>
      </div>

      <div class="col-12 col-md-12 mt-3">
        <owl-carousel-o [options]="(beneficiariosJson.length > 3) ? customOptionOneCarousel : customOptionOne">
          <ng-template carouselSlide *ngFor="let item of beneficiariosJson; let i = index">
            <div class="row benficiarios-m-max">
              <div class="col-sm-12 px-md-3 p-sm-3">
                <div class="cursor-pointer">
                  <app-card-beneficiaries [name]="item.title" [description]="item.descripcionBeneficiario"
                    [company]="item.empresaCiudad" [images]="item.imagePreview"
                    [redirect]="item.linkBeneficiario">
                  </app-card-beneficiaries>
                </div>
              </div>
            </div>
          </ng-template>
        </owl-carousel-o>
      </div>
    </div>

  </div>
</div>

<!-- Latin america & Caribe -->
<div class="container-fluid mt-5 mb-5 pb-2 back-gray">
  <div class="col-12">
    <div class="pt-5">
      <div class="row content-card1400">
        <div class="card border-card contenedor mx-auto" (click)="redirectPage(cards[0].url)"
          data-aos="fade-up" data-aos-offset="200" data-aos-easing="ease-in-out" data-aos-duration="400">
          <img [src]="cards[0].img" class="card-img-top dms-img" alt="img-logo">
          <div class="card-body">
            <h5 class="card-title text-center titulo-card-blue">{{'tituloCardsCaribe' | translate}}</h5>
            <p class="card-text descripcion-card text-center">{{'descripcionCardsCaribe' | translate}}</p>
          </div>
        </div>

        <div class="card border-card contenedor mx-auto" (click)="redirectPage(cards[1].url)" 
        data-aos="fade-up" data-aos-offset="200" data-aos-easing="ease-in-out" data-aos-duration="400">
          <img [src]="cards[1].img" class="card-img-top dms-img" alt="img-logo">
          <div class="card-body">
            <h5 class="card-title text-center titulo-card-blue">{{'tituloCardsLatinoAmerica' | translate}}</h5>
            <p class="card-text descripcion-card text-center">{{'descripcionCardsLatinoAmerica' | translate}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- News -->
<div class="container-fluid pb-5">
  <div class="row mt-5">
    <div class="col-12">
      <h1 class="title-green text-center text-uppercase">
        {{'newsletter' | translate}}
      </h1>
    </div>
  </div>
  <div class="col-12">
    <div class="row pt-5 mb-5 contenedor-sletter">
      <div class="card border-card contenedor-library mx-auto mt-3" *ngFor="let sletter of newsLetttersJson"
        [routerLink]="['/bulletin', sletter.id]" data-aos="fade-up" data-aos-duration="3000">
        <img [src]="sletter.previewImage" class="card-img-top dms-img box" alt="img-logo">
        <div class="card-body">
          <h5 class="card-title text-left titulo-card">{{sletter.title}}</h5>
          <p class="card-text descripcion-card text-left limit-text-card mt-1">
            {{sletter.summary}}</p>
        </div>
        <div class="card-footer">
          <hr class="line-card">
          <h6 class="card-text fecha-card text-right">{{sletter.ordenBoletin +"-"+ sletter.year}}</h6>
        </div>
      </div>
    </div>
  </div>

  <div class="row" *ngIf="newsLetttersJson.length > 0">
    <div class="mx-auto">
      <button class="btn back-green" routerLink="/news">
        {{'vermas' | translate}}
      </button>
    </div>
  </div>
</div>

<!-- Footer -->
<app-footer></app-footer>
