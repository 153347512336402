export const newsData = [
  {
    id: '01',
    title: '1 Lorem ipsum dolor sit amet, consectetur adipisicing elit. Animi, in! Fugit harum aliquam, laudantiumintroduccion',
    number: 1,
    image: '../../../assets/images/mock-1.png',
    description: 'quis quod sit aut repellatotam laboriosam, expedita possimus et similique! Voluptatem explicabo totam omnis temporibus, tenetu aperiam sit.',
  },
  {
    id: '02',
    title: '2 Lorem ipsum dolor sit amet, consectetur adipisicing elit. Animi, in! Fugit harum aliquam, laudantium',
    number: 2,
    image: '../../../assets/images/mock-2.png',
    description: 'quis quod sit aut repellatotam laboriosam, expedita possimus et similique! Voluptatem explicabo totam omnis temporibus, tenetu aperiam sit.',
  },
  {
    id: '03',
    title: '3 Lorem ipsum dolor sit amet, consectetur adipisicing elit. Animi, in! Fugit harum aliquam, laudantium',
    number: 3,
    image: '../../../assets/images/mock-3.png',
    description: 'quis quod sit aut repellatotam laboriosam, expedita possimus et similique! Voluptatem explicabo totam omnis temporibus, tenetu aperiam sit.',
  },
];

export const donorsData = [
  {
    id: '01',
    number: 1,
    image: '../../../assets/images/logo-canada.png',
    link: 'https://www.canada.ca/',
    description: `Global Affairs Canada leads Canada’s international development and humanitarian assistance. In the Caribbean, a region of focus for Canada's international development efforts, the aim is to help build a more prosperous and integrated Caribbean community, one that is able to generate clean economic growth, and eventually provide opportunity and security to its citizens.`,
  },
  {
    id: '02',
    title: '3 Lorem ipsum dolor sit amet, consectetur adipisicing elit. Animi, in! Fugit harum aliquam, laudantium',
    number: 2,
    image: '../../../assets/images/logo-ndf.png',
    link: 'https://www.ndf.fi/',
    description: `The Nordic Development Fund (NDF) is the joint development finance institution of the five Nordic countries. The objective of NDF's operations is to facilitate climate change investments in low-income countries. NDF finances in cooperation with bilateral and multilateral development institutions. The operations mirror the Nordic countries’ priorities in the areas of climate change and development.`,
  },
  {
    id: '03',
    number: 3,
    image: '../../../assets/images/logo-fomin.png',
    link: 'https://bidlab.org/',
    description: `IDB Lab supports economic growth and poverty reduction in Latin America and the Caribbean through encouraging increased private investment and advancing private sector development. It works with the private sector to develop, finance, and execute innovative business models that benefit entrepreneurs and poor and low-income households.`,
  },
];

export const projectsData = [
  {
    id: '01',
    title: 'Coomeva Fundation Colombia',
    number: 1,
    icon: 'nc-atom',
    region: 'latin-america',
    image: '../../../assets/images/mock-1.png',
    flag: '../../../assets/images/countries-flags/argentina.png',
    description: `Lorem Ipsum has been the industry's standard dummy text ever since the 1500s...`,
  },
  {
    id: '02',
    title: 'AFLUENTA (AR-T1176)',
    number: 2,
    icon: 'nc-bulb-63',
    region: 'latin-america',
    image: '../../../assets/images/mock-2.png',
    flag: '../../../assets/images/countries-flags/colombia.png',
    description: 'Afluenta, the most recent addition to the EcoMicro program, will be establishing and piloting a crowd...',
  },
  {
    id: '03',
    title: 'BELIZE CREDIT UNION LEAGUE (BCUL) BL-T1112',
    number: 3,
    icon: 'nc-cloud-download-93',
    region: 'latin-america',
    image: '../../../assets/images/mock-3.png',
    flag: '../../../assets/images/countries-flags/brasil.png',
    description: 'Belize Credit Union League will develop green loan products for adaptation technologies and methodologies that... build climate resilience of MSMEs in the agriculture and fisheries sectors of Belize.',
  },
  {
    id: '04',
    title: 'DEVELOPMENT FINANCE CORPORATION (DFC)',
    number: 4,
    icon: 'nc-bulb-63',
    region: 'latin-america',
    image: '../../../assets/images/mock-1.png',
    flag: '../../../assets/images/countries-flags/bolivia.png',
    description: 'Development Finance Corporation will pilot mitigation finance that increases access to Renewable... Energy/Energy Efficiency.',
  },
  {
    id: '05',
    title: 'DIACONÍA (BO-X1011)',
    number: 5,
    icon: 'nc-cloud-download-93',
    region: 'caribbean',
    image: '../../../assets/images/mock-2.png',
    flag: '../../../assets/images/countries-flags/barbados.png',
    description: 'By providing agriculture microinsurance, Diaconía aims to assist 300 agricultural producers and their families in the... Batallas.',
  },
  {
    id: '06',
    title: 'AFLUENTA (AR-T1176)',
    number: 6,
    icon: 'nc-atom',
    region: 'latin-america',
    image: '../../../assets/images/mock-3.png',
    flag: '../../../assets/images/countries-flags/colombia.png',
    description: `Lorem Ipsum has been the industry's standard dummy text ever since the 1500s...`,
  },
];

export const countriesFlags = [
  {
    id: '01',
    region: 'latin-america',
    name: 'Argentina',
    flag: '../../../assets/images/countries-flags/argentina.png',
  },
  {
    id: '02',
    region: 'latin-america',
    name: 'Colombia',
    flag: '../../../assets/images/countries-flags/colombia.png',
  },
  {
    id: '03',
    region: 'latin-america',
    name: 'Brazil',
    flag: '../../../assets/images/countries-flags/brasil.png',
  },
  {
    id: '04',
    region: 'latin-america',
    name: 'Bolivia',
    flag: '../../../assets/images/countries-flags/bolivia.png',
  },
  {
    id: '05',
    region: 'caribbean',
    name: 'Barbados',
    flag: '../../../assets/images/countries-flags/barbados.png',
  },
  {
    id: '06',
    region: 'latin-america',
    name: 'Mexico',
    flag: '../../../assets/images/countries-flags/mexico.png',
  },
  {
    id: '07',
    region: 'latin-america',
    name: 'Ecuador',
    flag: '../../../assets/images/countries-flags/ecuador.png',
  },
  {
    id: '08',
    region: 'latin-america',
    name: 'Guatemala',
    flag: '../../../assets/images/countries-flags/guatemala.png',
  },
  {
    id: '09',
    region: 'latin-america',
    name: 'Costa Rica',
    flag: '../../../assets/images/countries-flags/costa-rica.png',
  },
  {
    id: '10',
    region: 'caribbean',
    name: 'Bahamas',
    flag: '../../../assets/images/countries-flags/bahamas.png',
  },
  {
    id: '11',
    region: 'caribbean',
    name: 'Belize',
    flag: '../../../assets/images/countries-flags/belize.png',
  },
  {
    id: '12',
    region: 'caribbean',
    name: 'Dominica',
    flag: '../../../assets/images/countries-flags/dominica.png',
  },
  {
    id: '13',
    region: 'caribbean',
    name: 'Guyana',
    flag: '../../../assets/images/countries-flags/guyana.png',
  },
  {
    id: '14',
    region: 'caribbean',
    name: 'Jamaica',
    flag: '../../../assets/images/countries-flags/jamaica.png',
  },
  {
    id: '15',
    region: 'caribbean',
    name: 'Santa Lucia',
    flag: '../../../assets/images/countries-flags/st-lucia.png',
  },
];

export const beneficiaries = [
  {
    id: '01',
    name: 'Carlos Alvarado',
    workPosition: 'Transportista',
    company: 'Santiago Nonualco, La paz',
    image: '../../../assets/imagenesEcomicro/beneficiaries_CarAlv.png',
  },
  {
    id: '02',
    name: 'José Ernesto Contreras',
    workPosition: 'Tienda',
    company: 'Truck Mart - Ahuachapán',
    image: '../../../assets/imagenesEcomicro/beneficiaries_JoseErnContr.png',
  },
  {
    id: '03',
    name: 'Juan Gilberto Cañas ',
    workPosition: 'Director ejecutivo',
    company: 'Grupo publimovi',
    image: '../../../assets/imagenesEcomicro/beneficiaries_JuanGilbCanas.png',
  },
  {
    id: '04',
    name: 'Camilo Zapata',
    workPosition: 'Development',
    company: 'Sinapsis global',
    image: '../../../assets/images/mock-1.png',
  },
];

export const libraryData =[
  {
    id: '01',
    title: 'Lorem ipsum',
    preview: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.',
    content: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.',
    image: '../../../assets/images/mock-1.png',
    date: '14-09-2020'
  },
  {
    id: '02',
    title: 'Lorem ipsum',
    preview: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.',
    content: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.',
    image: '../../../assets/images/mock-2.png',
    date: '14-09-2020'
  },
  {
    id: '03',
    title: 'Lorem ipsum',
    preview: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.',
    content: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.',
    image: '../../../assets/images/mock-3.png',
    date: '14-09-2020'
  }
];
