<div class="page-carousel">
  <div class="filter"></div>
  <ngb-carousel #myCarousel="ngbCarousel">
    <!-- Primer template con la información del video de presentación -->
    
      <ng-template ngbSlide *ngFor="let item of slidesJson">
        <div class="header-1">
          <div class="page-header"
            style="background-image: url('../../../../assets/imagenesEcomicro/fondo_banner_slider.png');">
            <div class="filter"></div>
            <div class="content-center">
              <div class="container">
                <div class="row margin-mobile">
                  <div class="col-md-6">
                    <div class="iframe-container">
                      <iframe [src]="item.videoSlider | DomSafe"
                      title="videoIframe" frameborder="0" allowfullscreen height="250"></iframe>
                    </div>
                  </div>
                  <div class="col-md-6  ml-auto d-flex flex-wrap align-content-center justify-content-center">
                    <div class="content-description">
                      <h5 class="description">
                        <span>{{ item.title }}</span>
                      </h5>
                      <div class="text-processed" [innerHTML]="item.description"></div>
                    </div>
                    <br />
                    <div class="col-12">
                      <a routerLink="/about" class="btn back-green">
                        {{'leermas' | translate}}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-template>

    <!-- Segundo template con el ultimo boletín del sitio -->
    <ng-template ngbSlide>
      <div class="header-1">
        <div class="page-header" *ngIf="newSletters[0]"
          style="background-image: url('../../../../assets/imagenesEcomicro/banner4.jpg');">
          <div class="filter"></div>
          <div class="content-center">
            <div class="container">
              <div class="row margin-mobile">
                <div class="col-md-6 mt-3">
                  <img class="img-banner" [src]="newSletters[0].newsImage" alt="">
                </div>
                <div class="col-md-6  ml-auto d-flex flex-wrap align-content-center justify-content-center">
                  <div class="content-description">
                    <h5 class="description mt-4" >
                      <span>{{newSletters[0].newsTitle}}</span>
                    </h5>
                    <div class="text-processed" >
                      <p>{{newSletters[0].newsDescription.summary}}</p>
                    </div>
                  </div>
                  <br />
                  <div class="col-12">
                    <a [routerLink]="['/bulletin', newSletters[0].id]" class="btn back-green">
                      {{'leermas' | translate}}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </ngb-carousel>
</div>
