import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { SeoService } from 'src/app/core/services/seo.service';
import { SubscriptionService } from 'src/app/core/services/subscription.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss'],
})
export class ProjectsComponent implements OnInit {
  public form: UntypedFormGroup;
  siteKey: string;
  lang = 'en';

  get f() {
    return this.form.controls;
  }

  constructor(
    private subscriptionService: SubscriptionService,
    private formBuilder: UntypedFormBuilder,
    private ngxService: NgxUiLoaderService,
    private toastr: ToastrService,
    private modalService: NgbModal,
    private router: Router,
    private seoService: SeoService,
    private title: Title,
    ) {

    this.siteKey = environment.keyRecaptcha;

    this.router.events.subscribe((event: any) => {
      if (typeof event.url !== 'undefined') {
        if (event.url.includes('lang')) {
          this.ngOnInit();
        }
      }
    });
  }

  ngOnInit(): void {
    this.lang = localStorage.getItem('lang');
    this.loadValidations();
    this.flags();
  }

  private loadValidations(): void {
    this.form = this.formBuilder.group({
      email: ['', Validators.compose([
        Validators.required,
        Validators.maxLength(255),
        Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,6}$')
      ])],
      recaptcha: ['', Validators.required]
    });
  }

  SendProyect(): void {
    if (this.form.invalid) {
      this.toastr.warning('Ha ocurrido un inconveniente', 'Valide el formulario');
      return;
    }
    const form =
    {
      "data": {
        "type": "node--Suscripciones",
        "attributes": {
          "title": "Suscripcion",
          "field_suscripcion_email": this.form.value.email
        }
      }
    }

    this.startLoading();
    this.subscriptionService.sendFormSubscription(form).subscribe((result: any) => {

      if (result.data.id !== null) {
        this.stopLoading();
        this.toastr.success('Formulario enviado', '');
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      } else {
        this.stopLoading();
        this.toastr.warning('Ha ocurrido un inconveniente', '');
        console.error('error on insert form');
      }
    });
  }

  private startLoading() {
    this.ngxService.start();
    this.ngxService.startBackground('do-background-things');
  }

  private stopLoading() {
    this.ngxService.stop();
    this.ngxService.stopBackground('do-background-things');
  }

  open(content) {
    this.modalService.open(content);
  }

  flags() {
    this.seoService.generateFlags({
      title: 'Proyectos | EcoMicro se asocia con intermediarios financieros, ya sean bancos, uniones de crédito',
      site_name: 'EcoMicro',
      description: 'EcoMicro se asocia con intermediarios financieros, ya sean bancos, uniones de crédito, cooperativas de agricultores o empresas ancla, para diseñar e implementar proyectos piloto teniendo en cuenta la resiliencia climática.',
      slug: 'projects',
      image: 'https://ecomicro.azureedge.net/fondo-projects.db666105e0416b5a36e9.png'
    });
    this.title.setTitle('Proyectos | EcoMicro se asocia con intermediarios financieros, ya sean bancos, uniones de crédito');
  }
}
