<nav class="navbar navbar-expand-lg fixed-top navbar-transparent" color-on-scroll="300">
  <div class="container-fluid container-menu">
    <div class="navbar-translate pr-3">
      <div class="navbar-header menu-mobile">
        <a class="navbar-brand"></a>
      </div>
      <button class="navbar-toggler navbar-burger mb-3 ml-auto" type="button" data-toggle="collapse"
        data-target="#navbarToggler" aria-controls="navbarTogglerDemo02" aria-expanded="false"
        aria-label="Toggle navigation" (click)="sidebarToggle()">
        <span class="navbar-toggler-bar bar1"></span>
        <span class="navbar-toggler-bar bar2"></span>
        <span class="navbar-toggler-bar bar3"></span>
      </button>
    </div>
    <div class="navbar-collapse" id="navbarToggler">
      <ul class="navbar-nav w-100">
        <div class="contenintem">
          <li class="nav-item" [routerLinkActive]="['active']">
            <a class="nav-link" [routerLink]="['/home']">
              <span>
                {{'inicio' | translate}}
              </span>
            </a>
          </li>
          <li class="nav-item" [routerLinkActive]="['active']">
            <a class="nav-link" [routerLink]="['/about']">
              <span>
                {{'sobrenosotros' | translate}}
              </span>
            </a>
          </li>
          <li class="nav-item" [routerLinkActive]="['active']">
            <a class="nav-link" [routerLink]="['/projects']">
              <span>
                {{'proyectos' | translate}}
              </span>
            </a>
          </li>
          <li class="nav-item" [routerLinkActive]="['active']">
            <a class="nav-link" [routerLink]="['/latin-america']">
              <span>
                {{'americalatina' | translate}}
              </span>
            </a>
          </li>
          <li class="nav-item" [routerLinkActive]="['active']">
            <a class="nav-link" [routerLink]="['/caribbean']">
              <span>
                {{'caribe' | translate}}
              </span>
            </a>
          </li>
          <li class="nav-item" [routerLinkActive]="['active']">
            <a class="nav-link" [routerLink]="['/library']">
              <span>
                {{'biblioteca' | translate}}
              </span>
            </a>
          </li>
          <li class="nav-item" [routerLinkActive]="['active']">
            <a class="nav-link" [routerLink]="['/news']">
              <span>
                {{'noticias' | translate}}
              </span>
            </a>
          </li>
          <li class="nav-item" [routerLinkActive]="['active']">
            <a class="nav-link" [routerLink]="['/contact-us']">
              <span>
                {{'contactanos' | translate}}
              </span>
            </a>
          </li>
        </div>
        <li class="nav-item w-100 d-flex justify-content-end pr-leng">
          <div ngbDropdown class="dropdown">
            <a class="nav-link" id="dropdownBasic1" ngbDropdownToggle>
              <span *ngIf="defaultSpanish">
                {{'espanol' | translate}}
              </span>
              <span *ngIf="!defaultSpanish">
                {{'ingles' | translate}}
              </span>
            </a>
            <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="dropdown-primary">
              <a *ngIf="!defaultSpanish" class="dropdown-item" (click)="useLanguage('es')">{{'espanol' | translate}}</a>
              <a *ngIf="defaultSpanish" class="dropdown-item" (click)="useLanguage('en')">{{'ingles' | translate}}</a>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</nav>
