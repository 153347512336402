import { AfterViewInit, Component, OnInit, ViewChild, ViewEncapsulation, OnChanges, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { NgbCarousel } from '@ng-bootstrap/ng-bootstrap';
import { HomeService } from 'src/app/core/services/home.service';
import { LibraryService } from 'src/app/core/services/library.service';
import { NewsService } from 'src/app/core/services/news.service';

@Component({
  selector: 'app-banner-slider',
  templateUrl: './banner-slider.component.html',
  styleUrls: ['./banner-slider.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BannerSliderComponent implements OnInit, AfterViewInit {

  slidesJson:any[] = [];
  libraryData: any[] = [];
  dataOpen = false;
  data: Array<any>;
  lang = 'en';
  language = '';
  newSletters = [];
  @ViewChild('myCarousel') myCarousel: NgbCarousel;

  constructor(
    private homeService: HomeService,
    private router: Router,
    private newsService: NewsService,
    private libraryService: LibraryService
  ) {
    this.router.events.subscribe((event: any) => {
      if (typeof event.url !== 'undefined') {
        if (event.url.includes('lang')) {
          this.ngOnInit();
        }
      }
    });
  }

  ngOnInit(): void {
    this.lang = localStorage.getItem('lang');
    this.language = (this.lang === "en") ? 'EN' : 'ES';
    this.getnewsBanner();
    this.getSlidersJson();
  }

  ngAfterViewInit() {
    setTimeout(() => {
        this.myCarousel.next();
    });
  }

  getSlidersJson() {
    this.homeService.getSliderHome().subscribe( (res:any) => {
      let slidesEN = [];
      let slidesES = [];
      for (let i = 0; i < res.data.length; i++) {
        const homeSlides = res.data[i];
        if (homeSlides.attributes.langcode === 'en') {
          slidesEN.push({
            title: homeSlides.attributes.title,
            description: homeSlides.attributes.field_descripcion_silder.processed,
            videoSlider: homeSlides.attributes.field_video_slider.uri
          });
        } else if (homeSlides.attributes.langcode === 'es') {
          slidesES.push({
            title: homeSlides.attributes.title,
            description: homeSlides.attributes.field_descripcion_silder.processed,
            videoSlider: homeSlides.attributes.field_video_slider.uri
          });
        }
      }

      if (this.language === 'EN') {
        this.slidesJson = slidesEN
      } else {
        this.slidesJson = slidesES
      }
      
    })
  }

  getImagesJson(arr:Array<any>, id:any) {
    let finalimage;
    for (let i = 0; i < arr.length; i++) {
      const element = arr[i];
      if (id === element.id) {
        finalimage = element.attributes.uri.url
      }
    }
    return finalimage;
  }

  async getnewsBanner() {
    let bulletinEN = [];
    let bulletinES = [];
    const newsBulletin = await this.newsService.getNewsPromise();
    for (let i = 0; i < newsBulletin.data.length; i++) {
      const news = newsBulletin.data[i];
      if (news.attributes.langcode === 'en') {
        bulletinEN.push({
          id: news.id,
          newsTitle: news.attributes.title,
          newsDescription: news.attributes.field_descripcion_boletin,
          newsImage: `https://dev-ecomicro.pantheonsite.io${this.getImagesJson(newsBulletin.included, news.relationships.field_imagen_preview_boletin.data.id)}`
        });
      } else if (news.attributes.langcode === 'es') {
        bulletinES.push({
          id: news.id,
          newsTitle: news.attributes.title,
          newsDescription: news.attributes.field_descripcion_boletin,
          newsImage: `https://dev-ecomicro.pantheonsite.io${this.getImagesJson(newsBulletin.included, news.relationships.field_imagen_preview_boletin.data.id)}`
        });
      }
    }

    if (this.language === 'EN') {
      this.newSletters = bulletinEN;
      this.dataOpen = true;
    } else if (this.language === 'ES') {
      this.newSletters = bulletinES;
      this.dataOpen = true;
    }
  }

  orderBullets(data: Array<any>) {
    data.sort(function (a, b) {
      if (a.fieldAniosBoletin < b.fieldAniosBoletin) return +1;
      if (a.fieldAniosBoletin > b.fieldAniosBoletin) return -1;
      if (a.fieldAniosBoletin === b.fieldAniosBoletin) {
        if (a.fieldOrdenBoletin < b.fieldOrdenBoletin) return +1;
        if (a.fieldOrdenBoletin > b.fieldOrdenBoletin) return -1;
      }
    })
  }

  redirectTo(id: string) {
    window.open(`https://saecomicro.z19.web.core.windows.net/bulletin/${id}`, "_blank");
  }

}
