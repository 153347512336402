import { Injectable } from "@angular/core";
import { Apollo, gql } from "apollo-angular";
import { DocumentNode } from "graphql";
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { environment } from '../../../environments/environment'

@Injectable({
  providedIn: 'root'
})

export class NewsService {

  rates: any[];
  loading = true;
  error: any;
  options = {
    headers: new HttpHeaders({
      Authorization: 'Basic ' + btoa(`${environment.user}:${environment.pass}`)
    })
  }

  constructor(
    private apollo: Apollo,
    private http: HttpClient) { }

  getNewsletters() {
    return this.http.get(`${environment.urlApiJson}/ecomicro-api/node/boletin`, this.options);
  }

  getNewsPromise(newsID?:any) {
    return new Promise<any>((resolve, reject) => {
      if (newsID) {
        this.http.get(`${environment.urlApiJson}/ecomicro-api/node/boletin?filter[id]=${newsID}`, this.options).toPromise()
        .then( res => resolve(res),
        msg => reject(msg));
      } else {
        this.http.get(`${environment.urlApiJson}/ecomicro-api/node/boletin`, this.options).toPromise()
        .then( res => resolve(res),
        msg => reject(msg));
      }
    });
  }

  // getBullets(searchFilter: string, language: string, limit, offset, callback) {
  //   this.apollo.query<any>({
  //     query: this.getBulletsQuery(searchFilter, limit, offset, language),
  //     fetchPolicy: 'no-cache',
  //     context: {
  //       headers: {
  //         Authorization: 'Basic ' +
  //           btoa('Gojko._Vaso_234Irvine6423:rKpwMEd@6$Z%%Xgs#uF^UtnptHRS-_mfu2x$tawsb$GGQq8Kg3J&r&R_c?Krq$&9V3XyDr=Q@*-g$!77'),
  //       },
  //     }
  //   }).subscribe(
  //     (result) => { callback(result.data.nodeQuery.entities, result.data.nodeQuery.count); },
  //     (error) => { console.log(error) }
  //   )
  // }

  // getBulletsId(searchFilter: string, language: string, limit, offset, callback) {
  //   this.apollo.query<any>({
  //     query: this.getBulletsIdQuery(searchFilter, limit, offset, language),
  //     fetchPolicy: 'no-cache',
  //     context: {
  //       headers: {
  //         Authorization: 'Basic ' +
  //           btoa('Gojko._Vaso_234Irvine6423:rKpwMEd@6$Z%%Xgs#uF^UtnptHRS-_mfu2x$tawsb$GGQq8Kg3J&r&R_c?Krq$&9V3XyDr=Q@*-g$!77'),
  //       },
  //     }
  //   }).subscribe(
  //     (result) => { callback(result.data.nodeQuery.entities, result.data.nodeQuery.count); },
  //     (error) => { console.log(error) }
  //   )
  // }

  // private getBulletsQuery(filter: string, limit, offset, language: string): DocumentNode {
  //   return gql`query boletines {
  //         nodeQuery(limit: 100
  //           filter: {
  //             conditions: [{
  //               operator: EQUAL
  //               field: "status"
  //               value: "1"
  //               language: ${language}
  //             },
  //               {
  //                 operator: EQUAL
  //                 field: "type"
  //                 value: "Boletin"
  //               }]
  //           }
  //         ) {
  //           count
  //           entities {
  //             ... on NodeBoletin {
  //               uuid
  //               title
  //               fieldDescripcionBoletin {
  //                 summary
  //                 processed
  //               }
  //               fieldAniosBoletin
  //               fieldOrdenBoletin
  //               fieldImagenPreviewBoletin {
  //                 url
  //               }
  //               fieldImagenPrincipal {
  //                 url
  //               }
  //               fieldTituloCuadrosTexto
  //               fieldCuadrosTexto {
  //                 processed
  //               }
  //               fieldImagenesBloque1 {
  //                 url
  //               }
  //               fieldVideosBloque1 {
  //                 uri
  //               }
  //               fieldImagenesBloque2 {
  //                 url
  //               }
  //               fieldDescripcionesBloque2 {
  //                 processed
  //               }
  //               fieldImagenesBloque3 {
  //                 url
  //               }
  //               fieldDescripcionesBloque3 {
  //                 processed
  //               }
  //             }
  //           }
  //         }
  //       }`;
  // }

  // private getBulletsIdQuery(filter: string, limit, offset, language: string): DocumentNode {

  //   return gql`query boletines {
  //         nodeQuery(limit: 100
  //           filter: {
  //             conditions: [{
  //               operator: EQUAL
  //               field: "status"
  //               value: "1"
  //               language: ${language}
  //             },
  //             {
  //               operator: EQUAL
  //               field: "type"
  //               value: "Boletin"
  //             },
  //             {
  //               operator: EQUAL
  //               field: "uuid"
  //               value: "${filter}"
  //             }]
  //           }
  //         ) {
  //           count
  //           entities {
  //             ... on NodeBoletin {
  //               uuid
  //               title
  //               fieldDescripcionBoletin {
  //                 summary
  //                 processed
  //               }
  //               fieldImagenPrincipal {
  //                 url
  //               }
  //               fieldTituloCuadrosTexto
  //               fieldCuadrosTexto {
  //                 processed
  //               }
  //               fieldImagenesBloque1 {
  //                 url
  //               }
  //               fieldVideosBloque1 {
  //                 uri
  //               }
  //               fieldImagenesBloque2 {
  //                 url
  //               }
  //               fieldDescripcionesBloque2 {
  //                 processed
  //               }
  //               fieldImagenesBloque3 {
  //                 url
  //               }
  //               fieldDescripcionesBloque3 {
  //                 processed
  //               }
  //               fieldLinkBoletin
  //             }
  //           }
  //         }
  //       }`;
  // }

}
