<div class="wrapper banner-home">
  <div class="page-header section-dark" [ngStyle]="stylesBackgroundNews(dataBulletin.fieldImagenPrincipal.url)">
    <div class="filter"></div>
    <div class="content-center">
      <div class="container">

        <div class="d-flex justify-content-between flex-wrap align-content-center">
          <div class="col-12 col-sm-12 col-md-6 col-lg-6 h-100">
            <div class="title-brand mt-5 mb-3">
              <h1 class="banner-title">
                {{'news' | translate}}
              </h1>
            </div>
          </div>
          <div class="col-12 col-sm-12 col-md-6 col-lg-6 h-100">
            <div class="title-brand-description mt-4">
              <h3 class="description mt-4 text-center">
                <span class="color-green">
                  {{'financiamientoclimatico' | translate}}
                </span>
              </h3>
              <h5>
                {{'financiamientoclimaticoNews' | translate}}
              </h5>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>

<div class="container-main" *ngIf="dataBulletin.fieldTituloCuadrosTexto; else fieldTituloCuadrosTexto_NoDisplay">

  <div class="content-title">
    <h2>{{dataBulletin.title}}</h2>
    <div [ngClass]="{'principal-note': showShortDesciption}" class="div-text"
      [innerHTML]="dataBulletin.fieldDescripcionBoletin.processed"></div>
    <a class="btn-read" type="button" (click)="alterDescriptionText()">
      {{ showShortDesciption ? 'Read More': 'Read Less' }}
    </a>
  </div>

  <div class="content-side">
    <h2>
      {{
      dataBulletin.fieldTituloCuadrosTexto
      }}
    </h2>
    <ng-container *ngFor="let square of dataBulletin.fieldCuadrosTexto">
      <div [ngClass]="{'side-note': showShortDesciption}" [innerHTML]="square.processed"></div>
      <hr />
    </ng-container>
  </div>

</div>

<ng-template #fieldTituloCuadrosTexto_NoDisplay>
  <div class="content-title">
    <h2>{{dataBulletin.title}}</h2>
    <div [ngClass]="{'principal-note': showShortDesciption}" class="div-text"
      [innerHTML]="dataBulletin.fieldDescripcionBoletin.processed"></div>
    <a class="btn-read" type="button" (click)="alterDescriptionText()">
      {{ showShortDesciption ? 'Read More': 'Read Less' }}
    </a>
  </div>
</ng-template>

<a *ngIf="dataBulletin.fieldLinkBoletin" class="btn btn_bulletin" [href]="dataBulletin.fieldLinkBoletin"
  target="_blank">
  <p *ngIf="width > 767">
    {{'viewBulletin'| translate}}
  </p>
  <em class="far fa-newspaper"></em>
</a>

<div class="section-testimony" *ngIf="dataBulletinMedia.img.length > 0">
  <div class="row pt-dm">
    <div class="mx-auto d-block">
      <h2>
        Participant testimonials
      </h2>
    </div>
  </div>

  <div class="content-media">
    <div class="col-12">
      <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 mt-3" *ngFor="let img of dataBulletinMedia.img">
          <img class="img-participants img-fluid mx-auto d-block" [src]="img" alt="fondo principal">
        </div>
      </div>
    </div>


    <div class="col-12" *ngIf="dataBulletinMedia.video.length > 0">
      <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 mt-3" *ngFor="let video of dataBulletinMedia.video">
          <div class="embed-container">

            <iframe *ngIf="validarVideo(video.uri, 'youtube')" class="mx-auto d-block" [src]="video.uri | DomSafe"
              frameborder="0" title="Bulletin video 1"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen></iframe>


            <iframe *ngIf="validarVideo(video.uri, 'server')" class="mx-auto d-block" [src]="video.uri | DomSafe"
              type="video/mp4" frameborder="0" title="Bulletin video 2"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen></iframe>
          </div>
        </div>
      </div>
    </div>
  </div>


</div>

<div class="section-products" *ngIf="newProducts.length > 0">
  <div class="section-title">
    <h2 class="section-text">
      PARTNER Knowledge Products
    </h2>
  </div>

  <div class="section-father-rows d-flex" *ngFor="let product of newProducts">
    <div class="row" *ngIf="product.img">
      <div class="col-12 col-md-5">
        <img class="img-row img-fluid mx-auto d-block" [src]="product.img" alt="fondo principal">
      </div>
      <div class="text-rows col-12 col-md-7" [innerHTML]="product.text">
      </div>
    </div>

    <div class="col-12 text-rows" *ngIf="product.img === undefined">
      <div [innerHTML]="product.text"></div>
    </div>
  </div>
</div>

<app-footer></app-footer>