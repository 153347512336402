import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CountryService } from 'src/app/core/services/country.service';
import { ProjectsService } from 'src/app/core/services/projects.service';

@Component({
  selector: 'app-countrie-flags',
  templateUrl: './countrie-flags.component.html',
  styleUrls: ['./countrie-flags.component.scss'],
})
export class CountrieFlagsComponent implements OnInit {

  constructor(private router: Router, private countryService: CountryService,
    private projectsService: ProjectsService) {
    this.router.events.subscribe((event: any) => {
      if (typeof event.url !== 'undefined') {
        if (event.url.includes('lang')) {
          this.ngOnInit();
        }
      }
    });
  }

  @Input() region: string;

  countrieCaribbean: any[] = [];
  countrieLatinAmerica: any[] = [];

  public countriesFlags: any[];
  public countriesFlagsJson: any[];
  dataOpen = false;
  language = '';
  lang = 'en';

  ngOnInit(): void {
    this.lang = localStorage.getItem('lang');
    this.language = (this.lang === "en") ? 'EN' : 'ES';

    // Esto se hace para mostrar los paises de latino america en base a los proyectos
    // ya que solicitaron mostrar solamente los paises que contenian proyectos...
    // En caribe si se carga la totalidad de los paises: 26/03/2020
    if (this.region === "Latino América") {
      // this.getProjectsRegions();
    }
    this.getCoutnriesJson();
  }

  // getProjectsRegions() {
  //   this.projectsService.getProjects('', false, this.language, 100, 0, (academy, total) => {
  //     this.dataOpen = true;
  //     let newArray = [];
  //     let countryAmeLat = academy.filter(country => country.fieldPais.entity.fieldRegion === "Latino América");
  //     for (const country of countryAmeLat) {
  //       newArray.push(country.fieldPais.entity);
  //     }
  //     let hash = {};
  //     newArray = newArray.filter(country => hash[country.title] ? false : hash[country.title] = true);
  //     this.countriesFlags = newArray;
  //   });
  // }

  // getCountries() {
  //   this.countryService.getCountry('', this.language, 100, 0, (data, total) => {
  //     this.countriesFlags = data;
  //     this.dataOpen = true;
  //     this.filterCountries(this.region);
  //   });
  // }

  getImagesJson(arr:Array<any>, id:any) {
    let finalimage;
    for (let i = 0; i < arr.length; i++) {
      const element = arr[i];
      if (id === element.id) {
        finalimage = element.attributes.uri.url
      }
    }
    return finalimage;
  }

  // getCoutnriesJson() {
  //   this.countryService.getCountriesJson().subscribe( (res:any) => {
  //     let countriesEN = [];
  //     let countriesES = [];
  //     for (let i = 0; i < res.data.length; i++) {
        
  //       const country = res.data[i];
  //       if (country.attributes.langcode === 'en') {
  //         countriesEN.push({
  //           countryTitle: country.attributes.title,
  //           countryRegion: country.attributes.field_region,
  //           countryFlag: `https://dev-ecomicro.pantheonsite.io${this.getImagesJson(res.included, country.relationships.field_bandera.data.id)}`
  //         });
  //       } else if (country.attributes.langcode === 'es') {
  //         countriesES.push({
  //           countryTitle: country.attributes.title,
  //           countryRegion: country.attributes.field_region,
  //           countryFlag: `https://dev-ecomicro.pantheonsite.io${this.getImagesJson(res.included, country.relationships.field_bandera.data.id)}`
  //         });
  //       }
  //     }
  //     if (this.language === 'EN') {
  //       this.countriesFlagsJson = countriesEN;
  //     } else {
  //       this.countriesFlagsJson = countriesES;
  //     }

  //     this.dataOpen = true;
  //     this.filterCountries(this.region);
  //     console.log('countryJson', this.countriesFlagsJson);
  //   });
  // }

  async getCoutnriesJson() {    
    let countriesEN = [];
    let countriesES = [];
    const coRes = await this.countryService.getCountriesPromise();
    for (let i = 0; i < coRes.data.length; i++) {
      const country = coRes.data[i];
      if (country.attributes.langcode === 'en') {
        countriesEN.push({
          countryTitle: country.attributes.title,
          countryRegion: country.attributes.field_region,
          countryFlag: `https://dev-ecomicro.pantheonsite.io${this.getImagesJson(coRes.included, country.relationships.field_bandera.data.id)}`
        });
      } else if (country.attributes.langcode === 'es') {
        countriesES.push({
          countryTitle: country.attributes.title,
          countryRegion: country.attributes.field_region,
          countryFlag: `https://dev-ecomicro.pantheonsite.io${this.getImagesJson(coRes.included, country.relationships.field_bandera.data.id)}`
        });
      }
    }

    if (this.language === 'EN') {
      this.countriesFlagsJson = countriesEN;
    } else {
      this.countriesFlagsJson = countriesES;
    }

    this.dataOpen = true;
    this.filterCountries(this.region);    
  }

  filterCountries(regionPage:string) {
    this.countrieCaribbean = this.countriesFlagsJson.filter(region => region.countryRegion === regionPage);
    if (this.countrieCaribbean.length > 0 && this.region === regionPage) {
      this.countriesFlagsJson = this.ordenarPaises(this.countrieCaribbean);
    }
    
  }

  ordenarPaises(array: Array<any>) {
    return array.sort(function (a, b) {      
      if (a.countryTitle < b.countryTitle) return -1;
      if (a.countryTitle > b.countryTitle) return +1;
      if (a.countryTitle === b.countryTitle) return 0;
    })
  }
}
