import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ProjectsService } from 'src/app/core/services/projects.service';

@Component({
  selector: 'app-projects-content',
  templateUrl: './projects-content.component.html',
  styleUrls: ['./projects-content.component.scss']
})
export class ProjectsContentComponent implements OnInit {
  public projectsData: any;
  public getProjectCard = {};
  language = '';
  lang = 'en';
  system = { open: false };

  constructor(
    private route: ActivatedRoute,
    private projectsService: ProjectsService, private router: Router) {
    this.lang = localStorage.getItem('lang');
    this.language = (this.lang === 'en') ? 'EN' : 'ES';
  }


  ngOnInit(): void {
    this.route.params
      .subscribe(
        (params: Params) => {
          if (params['id'] !== undefined) {
            const id = params['id'];
            const newObject = new Object();
            newObject['uuid'] = id;
            this.projectsData = this.projectsService.singleProject;
            this.system.open = true;
            // this.projectsService.getProjects(newObject,
            //  false, this.language, 100, 0, (data, total) => {
            //   this.projectsData = data[0];
            //   this.system.open = true;
            // });
          } else {
            this.system.open = true;
          }
      });

    // console.log('interno', this.projectsService.singleProject);
  }

}
