import { Component, OnInit } from '@angular/core';
import { newsData } from '../../../core/mocks/data-news';

@Component({
  selector: 'app-card-news',
  templateUrl: './card-news.component.html',
  styleUrls: ['./card-news.component.scss']
})
export class CardNewsComponent implements OnInit {

  public newsData: any[] = newsData;

  constructor() { }

  ngOnInit(): void {

  }

}
