import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { donorsData, projectsData } from 'src/app/core/mocks/data-news';
import { SeoService } from 'src/app/core/services/seo.service';

@Component({
  selector: 'app-caribbean',
  templateUrl: './caribbean.component.html',
  styleUrls: ['./caribbean.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CaribbeanComponent implements OnInit {

  public projectsData: any[] = projectsData;
  public donorsData: any[] = donorsData;
  sizeWidth = 0;

  constructor(
    private seoService: SeoService,
    private title: Title,
  ) { }

  ngOnInit(): void {
    this.sizeWidth = window.innerWidth;
    this.flags();
  }

  flags() {
    this.seoService.generateFlags({
      title: 'Caribe | EcoMicro ha estado trabajando en siete países del Caribe para ayudar a las instituciones financieras',
      site_name: 'EcoMicro',
      description: 'Debido a su ubicación geográfica, los países del Caribe están en la primera línea del cambio climático, con siempre -huracanes más fuertes, sequías severas, el calentamiento de las aguas del océano y el aumento del nivel del mar producen impactos desproporcionados en los pequeños países insulares.',
      slug: 'caribbean',
      image: 'https://ecomicro.azureedge.net/caribbean.ab3b9835c6d144398704.png'
    });
    this.title.setTitle('Caribe | EcoMicro ha estado trabajando en siete países del Caribe para ayudar a las instituciones financieras');
  }

}
