import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-card-beneficiaries',
  templateUrl: './card-beneficiaries.component.html',
  styleUrls: ['./card-beneficiaries.component.scss']
})
export class CardBeneficiariesComponent implements OnInit {

  @Input() name: string;
  @Input() description: string;
  @Input() images: string;
  @Input() company: string;
  @Input() redirect: string;


  constructor() { }

  ngOnInit(): void {
  }

  redirectTo(redirect){
    if (redirect) {
      window.open(redirect, '_blank');
    }
  }
}
