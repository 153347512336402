import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { NewsService } from 'src/app/core/services/news.service';
import { SeoService } from 'src/app/core/services/seo.service';
import { SubscriptionService } from 'src/app/core/services/subscription.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss'],
  //encapsulation: ViewEncapsulation.none
})
export class NewsComponent implements OnInit {
  closeResult = '';
  newSletters = [];
  newsLetttersJson = [];
  dataOpen = false;
  language = '';
  lang = 'en';

  public form: UntypedFormGroup;
  siteKey: string;

  get f() {
    return this.form.controls;
  }


  constructor(
    private router: Router, 
    private newsService: NewsService,
    private subscriptionService: SubscriptionService,
    private formBuilder: UntypedFormBuilder,
    private ngxService: NgxUiLoaderService,
    private toastr: ToastrService,
    private modalService: NgbModal,
    private seoService: SeoService,
    private title: Title,
    ) {

    this.siteKey = environment.keyRecaptcha;
    this.router.events.subscribe((event: any) => {
      if (typeof event.url !== 'undefined') {
        if (event.url.includes('lang')) {
          this.ngOnInit();
        }
      }
    });
   }

  ngOnInit(): void {
    this.lang = localStorage.getItem('lang');
    this.language = (this.lang === "en") ? 'EN' : 'ES';
    this.getNewsLetterJson();
    this.loadValidations();
    this.flags();
  }

  private loadValidations(): void {
    this.form = this.formBuilder.group({
      email: ['', Validators.compose([
        Validators.required,
        Validators.maxLength(255),
        Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,6}$')
      ])],
      recaptcha: ['', Validators.required]
    });
  }

  getImagesJson(arr:Array<any>, id:any) {
    let finalimage;
    for (let i = 0; i < arr.length; i++) {
      const element = arr[i];
      if (id === element.id) {
        finalimage = element.attributes.uri.url
      }
    }
    return finalimage;
  }

  async getNewsLetterJson () {
    let newsEN = [];
    let newsES = [];
    const newsLetter = await this.newsService.getNewsPromise();

    for (let i = 0; i < newsLetter.data.length; i++) {
      const news = newsLetter.data[i];
      
      if (news.attributes.langcode === 'en') {
        newsEN.push({
          id: news.id,
          title: news.attributes.title,
          summary: news.attributes.field_descripcion_boletin.summary,
          ordenBoletin: news.attributes.field_orden_boletin,
          year: news.attributes.field_anios_boletin,
          status: news.attributes.status,
          previewImage: `https://dev-ecomicro.pantheonsite.io${this.getImagesJson(newsLetter.included, news.relationships.field_imagen_preview_boletin.data.id)}`
        });
      } else if (news.attributes.langcode === 'es') {
        newsES.push({
          id: news.id,
          title: news.attributes.title,
          summary: news.attributes.field_descripcion_boletin.summary,
          ordenBoletin: news.attributes.field_orden_boletin,
          year: news.attributes.field_anios_boletin,
          status: news.attributes.status,
          previewImage: `https://dev-ecomicro.pantheonsite.io${this.getImagesJson(newsLetter.included, news.relationships.field_imagen_preview_boletin.data.id)}`
        });
      }
    }

    if (this.language === 'EN') {
      this.newsLetttersJson = newsEN;
      this.orderBullets(this.newsLetttersJson);
      this.dataOpen = true;
    } else {
      this.newsLetttersJson = newsES;
      this.orderBullets(this.newsLetttersJson);
      this.dataOpen = true;
    }
  }

  // getNewsletterJson() {
  //   this.newsService.getNewsletters().subscribe( (res:any) => {
  //     let newsEN = [];
  //     let newsES = [];

  //     for (let i = 0; i < res.data.length; i++) {
  //       const news = res.data[i];
        
  //       if (news.attributes.langcode === 'en') {
  //         newsEN.push({
  //           id: news.id,
  //           title: news.attributes.title,
  //           summary: news.attributes.field_descripcion_boletin.summary,
  //           ordenBoletin: news.attributes.field_orden_boletin,
  //           year: news.attributes.field_anios_boletin,
  //           status: news.attributes.status,
  //           previewImage: `https://dev-ecomicro.pantheonsite.io${this.getImagesJson(res.included, news.relationships.field_imagen_preview_boletin.data.id)}`
  //         });
  //       } else if (news.attributes.langcode === 'es') {
  //         newsES.push({
  //           id: news.id,
  //           title: news.attributes.title,
  //           summary: news.attributes.field_descripcion_boletin.summary,
  //           ordenBoletin: news.attributes.field_orden_boletin,
  //           year: news.attributes.field_anios_boletin,
  //           status: news.attributes.status,
  //           previewImage: `https://dev-ecomicro.pantheonsite.io${this.getImagesJson(res.included, news.relationships.field_imagen_preview_boletin.data.id)}`
  //         });
  //       }
  //     }

  //     if (this.language === 'EN') {
  //       this.newsLetttersJson = newsEN;
  //       this.orderBullets(this.newsLetttersJson);
  //       this.dataOpen = true;
  //     } else {
  //       this.newsLetttersJson = newsES;
  //       this.orderBullets(this.newsLetttersJson);
  //       this.dataOpen = true;
  //     }
  //   });
  // }

  // getNews() {
  //   this.newsService.getBullets('', this.language, 100, 0, (data, total) => {
  //     this.orderBullets(data);
  //     this.newSletters = data;
  //     this.dataOpen = true;
  //   })
  // }

  orderBullets(data: Array<any>) {
    data.sort(function (a, b) {
      if (a.year < b.year) return +1;
      if (a.year > b.year) return -1;
      if (a.year === b.year) {
        if (a.ordenBoletin < b.ordenBoletin) return +1;
        if (a.ordenBoletin > b.ordenBoletin) return -1;
      }
    })
  }

  SendProyect(): void {   
    if (this.form.invalid) {
      this.toastr.warning('Ha ocurrido un inconveniente', 'Valide el formulario');
      return;
    }
    const form =
    {
      "data": {
          "type": "node--Suscripciones",
          "attributes": {
            "title" : "Suscripcion",
            "field_suscripcion_email": this.form.value.email
          }
        }
    }   

    this.startLoading();
    this.subscriptionService.sendFormSubscription(form).subscribe((result: any) => {
      
      if (result.data.id !== null) {
        this.stopLoading();
        this.toastr.success('Formulario enviado', '');
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      } else {
        this.stopLoading();
        this.toastr.warning('Ha ocurrido un inconveniente', '');
        console.error('error on insert form');
      }
    });
  }

  private startLoading() {
    this.ngxService.start();
    this.ngxService.startBackground('do-background-things');
  }

  private stopLoading() {
    this.ngxService.stop();
    this.ngxService.stopBackground('do-background-things');
  }

  open(content) {
    this.modalService.open(content);
  }

  flags() {
    this.seoService.generateFlags({
      title: 'Noticias | FINANCIAMIENTO CLIMÁTICO INCLUSIVO',
      site_name: 'EcoMicro',
      description: 'Manténgase actualizado sobre los proyectos EcoMicro en la región',
      slug: 'news',
      image: 'https://ecomicro.azureedge.net/fondo-news.2f4728aac7e43e9b5fb1.png'
    });
    this.title.setTitle('Noticias | FINANCIAMIENTO CLIMÁTICO INCLUSIVO');
  }

}
