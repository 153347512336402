import { AfterViewInit, Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { FormContactService } from 'src/app/core/services/form-contact.service';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {

  public form: UntypedFormGroup;
  siteKey: string;
  get f() {
    return this.form.controls;
  }
  submitted = false;
  lang = 'en';

  constructor(private formContactService: FormContactService,
    private formBuilder: UntypedFormBuilder,
    private toastr: ToastrService,
    private router: Router, 
    private ngxService: NgxUiLoaderService
  ) {
    this.siteKey = environment.keyRecaptcha;
    this.router.events.subscribe((event: any) => {
      if (typeof event.url !== 'undefined') {
        if (event.url.includes('lang')) {
          this.ngOnInit();
        }
      }
    });
  }

  ngOnInit(): void {
    this.lang = localStorage.getItem('lang');
    this.loadValidations(); 
  }

  private startLoading() {
    this.ngxService.start();
    this.ngxService.startBackground('do-background-things');
  }

  private stopLoading() {
    this.ngxService.stop();
    this.ngxService.stopBackground('do-background-things');
  }

  private loadValidations(): void {
    this.form = this.formBuilder.group({
      name: ['', Validators.compose([Validators.required, Validators.maxLength(255),
      Validators.pattern('^[a-zA-Z ]+$')])],
      email: ['', Validators.compose([Validators.required, Validators.maxLength(255),
      Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,6}$')
      ])],
      description: ['', Validators.compose([Validators.required, Validators.maxLength(1024)])],
      recaptcha: ['', Validators.required]
    });
  }

  SendProyect(): void {
    this.submitted = true;
    if (this.form.invalid) {
      this.toastr.warning('Ha ocurrido un inconveniente', 'Valide el formulario');
      return;
    }
    const form =
    {
      'data': {
        'type': 'node--formulario_contacto',
        'attributes': {
          'title': 'formulario contacto',
          'field_nombre': this.f.name.value,
          'field_email': this.f.email.value,
          'field_mensaje': this.f.description.value,
        }
      }
    };

    this.startLoading();
    this.formContactService.sendContactUsStore(form).subscribe((result: any) => {
      if (result.data.id !== null) {
        this.stopLoading();
        this.toastr.success('Formulario enviado', '');
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else {
        this.stopLoading();
        this.toastr.warning('Ha ocurrido un inconveniente', '');
        console.error('error on insert form');
      }
    });
  }

}
