import { ViewLibraryComponent } from './modules/view-library/view-library.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';
import { HomeComponent } from './modules/home/home.component';
import { AboutComponent } from './modules/about/about.component';
import { ProjectsComponent } from './modules/projects/projects.component';
import { LatinAmericaComponent } from './modules/latin-america/latin-america.component';
import { CaribbeanComponent } from './modules/caribbean/caribbean.component';
import { LibraryComponent } from './modules/library/library.component';
import { NewsComponent } from './modules/news/news.component';
import { ContactUsComponent } from './modules/contact-us/contact-us.component';
import { ProjectsContentComponent } from './shared/components/projects-content/projects-content.component';
import { BulletinComponent } from './modules/bulletin/bulletin.component';

const routerOptions: ExtraOptions = {
  useHash: true,
  anchorScrolling: 'enabled',
  scrollPositionRestoration: 'enabled',
  // scrollOffset: [0, 64],
};

const APP_ROUTES: Routes = [
  { path: 'home', component: HomeComponent },
  { path: 'about', component: AboutComponent },
  { path: 'projects', component: ProjectsComponent },
  { path: 'projects/:id', component: ProjectsComponent },
  { path: 'latin-america', component: LatinAmericaComponent },
  { path: 'caribbean', component: CaribbeanComponent },
  { path: 'library', component: LibraryComponent },
  { path: 'library-view/:id', component: ViewLibraryComponent },
  { path: 'news', component: NewsComponent },
  { path: 'bulletin', component: BulletinComponent },
  { path: 'bulletin/:id', component: BulletinComponent },
  { path: 'contact-us', component: ContactUsComponent },
  { path: 'project/:id', component: ProjectsContentComponent },
  { path: '**', redirectTo: 'home' },
];

@NgModule({
  imports: [RouterModule.forRoot(APP_ROUTES)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
