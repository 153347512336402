<div class="container-fluid-form">
  <form autocomplete="off" [formGroup]="formulario">
    <div class="search col-12 col-md-12">
      <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-12">
          <div class="row">
            <div class="col-12 margin-desktop">
              <div class="row">
                <kendo-textbox-container class="display-cont">
                  <input [(ngModel)]="inputSearch" kendoTextBox class="searchTerm" style="margin-bottom: 9px;" id="description"
                    formControlName="description" (keyup)="searchBy()" [placeholder]="'termSearch' | translate" />
                  <button type="submit" class="searchButton">
                    <em class="fa fa-search"></em>
                  </button>
                </kendo-textbox-container>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-6 col-md-6 col-sm-12 col-12">
          <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-12 col-12 m-mobile">
              <label class="col-3 col-sm-3 col-md-12">{{'countries' | translate}}</label>
              <kendo-combobox class="col-9 col-sm-9 col-md-12" [data]="listCountries"
                [placeholder]="'searchCountry' | translate" id="country" formControlName="country"
                [(ngModel)]="selectCoutry" (valueChange)="searchBy()"></kendo-combobox>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-12 m-mobile">
              <label class="col-3 col-sm-3 col-md-12">{{'topic' | translate}}</label>
              <kendo-combobox class="col-9 col-sm-9 col-md-12" [data]="listTopics"
                [placeholder]="'searchTopic' | translate" id="topic" formControlName="topic"
                [(ngModel)]="selectTopic" (valueChange)="searchBy()"></kendo-combobox>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>

<ng-container *ngIf="projectDataJson == undefined">
  <div class="spinner-container d-flex justify-content-center">
    <div class="spinner-border text-success" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
</ng-container>

<div class="row">
  <ng-container *ngFor="let project of projectFiltered">
    <div class="col-md-4 col-sm-4 col-xs-4 p-5">
      <div class="card" data-background="image"
        [style.background-image]="'url('+ project.imageProject +')'">
        <div class="card-body dms-card">
          <div class="col-12">
            <div class="container-titles-h6">
              <h6 class="card-category">{{ project.title }}</h6>
              <h6 class="type-project">{{project.projectType }}</h6>
            </div>
            <div class="card-icon-projects">
              <img [src]="project.projectIcon" alt="icono" class="nc-icon"
                style="max-width: 100px;">
            </div>
            <div class="ellipsis-card-project" ellipsis>
              <p class="card-description ">{{ project.descriptionProj.summary }}</p>
            </div>
            <div class="card-footer">
              <a href="javascript:void(0)" (click)="content(project)">
                <i class="fa fa-book" aria-hidden="true"></i> {{'vermas' | translate}}
              </a>
            </div>
            <div class="flag d-flex justify-content-end">
              <img [src]="project.countryImage" [alt]="project.countryTitle">
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>
