import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ProjectsModel } from 'src/app/core/models/projects.model';
import { CountryService } from 'src/app/core/services/country.service';
import { ProjectsService } from 'src/app/core/services/projects.service';

@Component({
  selector: 'app-card-all-projects',
  templateUrl: './card-all-projects.component.html',
  styleUrls: ['./card-all-projects.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CardAllProjectsComponent implements OnInit {

  projectsData: any[];
  projectDataJson:any[];
  language = '';
  lang = 'en';
  @Input() filters: object;
  dataOpen = false;
  public formulario: UntypedFormGroup;
  dataForm = { country: '', description: '', topic: '' };
  public listCountries: Array<string> = [];

  public listTopics: Array<string> = [];
  data = [];

  inputSearch:string = '';
  selectCoutry:string = '';
  selectTopic:string = '';
  projectFiltered:ProjectsModel[]; 

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    private countryService: CountryService,
    private projectsService: ProjectsService) {
    this.router.events.subscribe((event: any) => {
      if (typeof event.url !== 'undefined') {
        if (event.url.includes('lang')) {
          this.ngOnInit();
        }
      }
    });

  }

  ngOnInit(): void {
    this.buildForm();
    this.lang = localStorage.getItem('lang');
    this.language = (this.lang === "en") ? 'EN' : 'ES';
    this.getCountries().then((x) => {
      this.paramActive();
    });
    this.getTopic();
    this.getProjects();
  }

  private paramActive(): void {
    this.route.params
      .subscribe(
        (params: Params) => {
          if (params['id'] !== undefined) {
            const id = params['id'];
            this.formulario.controls.country.setValue(id);
          }
    });
  }

  private buildForm() {
    this.formulario = this.formBuilder.group({
      description: ['', []],
      country: ['', []],
      topic: ['', []],
    });
  }

  getTopic() {
    this.listTopics = this.countryService.getTopic(this.lang);
  }

  async getCountries() {
    let countryES = [];
    let countryEN = [];
    const countries = await this.countryService.getCountriesPromise();
    for (let i = 0; i < countries.data.length; i++) {
      const country = countries.data[i];
      if (country.attributes.langcode === 'en') {
        countryEN.push(country.attributes.title)
      } else if (country.attributes.langcode === 'es') {
        countryES.push(country.attributes.title)
      }
    }
    if (this.language === 'EN') {
      this.listCountries = countryEN;
    } else if (this.language === 'ES') {
      this.listCountries = countryES;
    }
    let respCountryFilter = this.crearArrayPaises(this.listCountries);
    this.listCountries = this.ordenarPaises(respCountryFilter);
    this.dataOpen = true;
  }

  crearArrayPaises(data: Array<any>) {
    if (this.lang === "en") data.push('All');
    if (this.lang === "es") data.push('Todos');
    return data;
  }

  ordenarPaises(data: Array<any>) {
    return data.sort((a, b) => {
      if (a > b) return +1;
      if (a < b) return -1;
      if (a === b) return 0;
    });
  }


  getImagesJson(arr:Array<any>, id:any) {
    let finalimage;
    for (let i = 0; i < arr.length; i++) {
      const element = arr[i];
      if (id === element.id) {
        finalimage = element.attributes.uri.url
      }
    }
    return finalimage;
  }

  async getProjects() {
    let projEN:ProjectsModel[] = [];
    let projES:ProjectsModel[] = [];
    const projects = await this.projectsService.getProjectsPromise();
    const icons = await this.projectsService.getIconsPromise();
    const countries = await this.countryService.getCountriesPromise();

    for (let i = 0; i < projects.data.length; i++) {
      const proj = projects.data[i];
      if (proj.attributes.langcode === 'en') {
        for (let i = 0; i < icons.data.length; i++) {
          const icon = icons.data[i];
          for (let i = 0; i < countries.data.length; i++) {
            const co = countries.data[i];
            if (proj.relationships.field_icono.data.id === icon.id) {
              if (co.id === proj.relationships.field_pais.data.id) {
                projEN.push({
                  id: proj.id,
                  imageProject: `https://dev-ecomicro.pantheonsite.io${this.getImagesJson(projects.included, proj.relationships.field_imagen_proyecto.data.id)}`,
                  title: proj.attributes.title,
                  descriptionProj: proj.attributes.field_descripcion_proyecto,
                  projectType: proj.attributes.field_tipo_proyecto,
                  projectIcon: `https://dev-ecomicro.pantheonsite.io${this.getImagesJson(icons.included, icon.relationships.field_imagen_icono.data.id)}`,
                  countryImage: `https://dev-ecomicro.pantheonsite.io${this.getImagesJson(countries.included, co.relationships.field_bandera.data.id)}`,
                  countryTitle: co.attributes.title,
                  projectVideo: co.attributes.field_video_proyecto
                });
              }
            }
            
          }
        }
      } else if (proj.attributes.langcode === 'es') {
        for (let i = 0; i < icons.data.length; i++) {
          const icon = icons.data[i];
          for (let i = 0; i < countries.data.length; i++) {
            const co = countries.data[i];
            if (proj.relationships.field_icono.data.id === icon.id) {
              if (co.id === proj.relationships.field_pais.data.id) {
                projES.push({
                  id: proj.id,
                  imageProject: `https://dev-ecomicro.pantheonsite.io${this.getImagesJson(projects.included, proj.relationships.field_imagen_proyecto.data.id)}`,
                  title: proj.attributes.title,
                  descriptionProj: proj.attributes.field_descripcion_proyecto,
                  projectType: proj.attributes.field_tipo_proyecto,
                  projectIcon: `https://dev-ecomicro.pantheonsite.io${this.getImagesJson(icons.included, icon.relationships.field_imagen_icono.data.id)}`,
                  countryImage: `https://dev-ecomicro.pantheonsite.io${this.getImagesJson(countries.included, co.relationships.field_bandera.data.id)}`,
                  countryTitle: co.attributes.title,
                  projectVideo: co.attributes.field_video_proyecto
                });
              }
            }
          }
        }
      }
    }

    
    if (this.language === 'EN') {
      this.projectDataJson = projEN;
      this.projectFiltered = projEN;
      // console.log('project', this.projectDataJson);
      this.projectsService.allProjects = this.projectDataJson
    } else {
      this.projectDataJson = projES;
      this.projectFiltered = projES;
      this.projectsService.allProjects = this.projectDataJson
    } 
  }

  content(project: any): any {
    this.router.navigate([`/project/${project.id}`]);
    window.scroll(0, 0);

    this.projectsService.singleProject = project
  }

  searchBy() {
    if (this.inputSearch || this.selectCoutry || this.selectTopic) {
      if (this.inputSearch) {
        this.projectFiltered = this.projectDataJson.filter( (project:ProjectsModel) => 
        project.title.toLowerCase().includes(this.inputSearch.toLowerCase())); 
      }
      if (this.selectCoutry) {
        this.projectFiltered = this.projectDataJson.filter( (project:ProjectsModel) => 
        project.countryTitle.toLowerCase().includes(this.selectCoutry.toLowerCase())); 
      }
      if (this.selectTopic) {
        this.projectFiltered = this.projectDataJson.filter( (project:ProjectsModel) => 
        project.projectType.toLowerCase().includes(this.selectTopic.toLowerCase())); 
      }
    } else {
      this.projectFiltered = this.projectDataJson;
    }
    
  }

  // searchData() {
  //   let data = this.formulario.value;
  //   var newObject = new Object();

  //   if (data.description.length > 0) newObject['description'] = data.description;

  //   if (data.country != undefined && data.country.length > 0) {
  //     if (data.country != 'All' && data.country != 'Todos') newObject['country'] = data.country;
  //   }

  //   if (data.topic != undefined && data.topic.length > 0) {
  //     if (data.topic != 'All' && data.topic != 'Todos') newObject['topic'] = data.topic;
  //   }

  //   this.projectsService.getProjects(newObject, false ,this.language, 100, 0, (data, count) => {
  //     this.projectsData = [];
  //     this.projectsData = data;

  //     if (data.length === 0 && newObject['description'].length > 0) {
  //       this.projectsService.getProjects(newObject, true ,this.language, 100, 0, (data, count) => {
  //         this.projectsData = [];
  //         this.projectsData = data;
  //       });
  //     }
  //   });
  // }
}
