<div class="contactus-1 section-image settings-img dms-img"
  style="background-image: url('../../../assets/images/banner-fondo.png')">

  <div class="container">
    <div class="row">

      <div class="col-12 col-sm-12 col-md-9 col-lg-6 ml-auto mr-auto mt-4">

        <div class="card card-contact no-transition">

          <h3 class="card-title text-center format-title">{{'contactanos' | translate}}</h3>

          <div class="row">

            <div class="col-md-9 col-lg-9 mx-auto">
              <form role="form" id="contact-form" method="post" [formGroup]="form" (ngSubmit)="SendProyect()"
                novalidate>
                <div class="card-body">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group label-floating">
                        <input type="text" name="name" formControlName="name" class="form-control form-contact"
                          placeholder="{{'name' | translate}}" [class.is-invalid]="f.name.invalid && f.name.touched">
                        <div class="alert-message" *ngIf="(f.name.invalid && f.name.touched) || f.name.dirty">
                          <small *ngIf="f.name.errors?.required" class="text-danger">Ingrese su nombre</small>
                          <!--question mark(?) is a safe navigation operator-->
                          <small *ngIf="f.name.errors?.pattern" class="text-danger">Ingrese su nombre, solo letras
                          </small>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-group label-floating">
                    <input type="email" name="email" formControlName="email" class="form-control form-contact"
                      placeholder="{{'email' | translate}}" [class.is-invalid]="f.email.invalid && f.email.touched" />
                    <div class="alert-message" *ngIf="(f.email.invalid && f.email.touched) || f.email.dirty">
                      <small *ngIf="f.email.errors?.required" class="text-danger">Ingrese su dirección de
                        correo electrónico</small>
                      <!--question mark(?) is a safe navigation operator-->
                      <small *ngIf="f.email.errors?.pattern" class="text-danger">Ingrese su dirección de
                        correo electrónico válido
                      </small>
                    </div>
                  </div>
                  <div class="form-group label-floating">
                    <textarea name="description" formControlName="description" class="form-control form-contact"
                      id="message" rows="6" placeholder="{{'message' | translate}}"
                      [class.is-invalid]="f.description.invalid && f.description.touched">
                    </textarea>
                  </div>
                  <div class="mx-auto d-flex">
                      <ngx-recaptcha2 class="g-recaptcha mx-auto w-100" #captchaElem 
                      [siteKey]="siteKey" formControlName="recaptcha" [hl]="lang">
                      </ngx-recaptcha2>
                  </div>

                  <div class="text-center mt-2 mb-4">
                    <button type="submit" class="btn back-green">{{'send' | translate}}</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-footer></app-footer>
