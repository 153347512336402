import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FormContactService {

  constructor(private http: HttpClient) { }

  public sendContactUsStore(form) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/vnd.api+json',
        // tslint:disable-next-line:object-literal-key-quotes
        'Accept': '*/*',
        'Authorization':'Basic ' + window.btoa(`${environment.user}:${environment.pass}`),
      }),
    };
    return this.http.post(`${environment.urlApiJson}/ecomicro-api/node/formulario_contacto`, form, httpOptions);
  }
}
