import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { SubscriptionService } from 'src/app/core/services/subscription.service';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { SeoService } from 'src/app/core/services/seo.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-library',
  templateUrl: './library.component.html',
  styleUrls: ['./library.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LibraryComponent implements OnInit {

  public form: UntypedFormGroup;
  siteKey: string;
  lang = 'en';

  get f() {
    return this.form.controls;
  }

  constructor(
    private subscriptionService: SubscriptionService,
    private formBuilder: UntypedFormBuilder,
    private ngxService: NgxUiLoaderService,
    private modalService: NgbModal,
    private toastr: ToastrService,
    private router: Router,
    private seoService: SeoService,
    private title: Title,
    ) {

    this.siteKey = environment.keyRecaptcha;

    this.router.events.subscribe((event: any) => {
      if (typeof event.url !== 'undefined') {
        if (event.url.includes('lang')) {
          this.ngOnInit();
        }
      }
    });
  }

  ngOnInit(): void {
    this.lang = localStorage.getItem('lang');
    this.loadValidations();
    this.flags();
  }

  private loadValidations(): void {
    this.form = this.formBuilder.group({
      email: ['', Validators.compose([
        Validators.required,
        Validators.maxLength(255),
        Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,6}$')
      ])],
      recaptcha: ['', Validators.required]
    });
  }

  SendProyect(): void {
    if (this.form.invalid) {
      this.toastr.warning('Ha ocurrido un inconveniente', 'Valide el formulario');
      return;
    }
    const form =
    {
      "data": {
        "type": "node--Suscripciones",
        "attributes": {
          "title": "Suscripcion",
          "field_suscripcion_email": this.form.value.email
        }
      }
    }

    this.startLoading();
    this.subscriptionService.sendFormSubscription(form).subscribe((result: any) => {

      if (result.data.id !== null) {
        this.stopLoading();
        this.toastr.success('Formulario enviado', '');
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      } else {
        this.stopLoading();
        this.toastr.warning('Ha ocurrido un inconveniente', '');
        console.error('error on insert form');
      }
    });
  }

  private startLoading() {
    this.ngxService.start();
    this.ngxService.startBackground('do-background-things');
  }

  private stopLoading() {
    this.ngxService.stop();
    this.ngxService.stopBackground('do-background-things');
  }

  open(content) {
    this.modalService.open(content);
  }

  flags() {
    this.seoService.generateFlags({
      title: 'Blibioteca | Compartir conocimientos es un componente importante del enfoque EcoMicro',
      site_name: 'EcoMicro',
      description: 'Compartir conocimientos es un componente importante del enfoque EcoMicro. Queremos que otros aprendan de la gran experiencia que nuestros socios están acumulando a medida que desarrollan e implementan proyectos de financiamiento verde en América Latina y el Caribe.',
      slug: 'library',
      image: 'https://ecomicro.azureedge.net/fondo-library.f99551b090bfcee28fde.png'
    });
    this.title.setTitle('Blibioteca | Compartir conocimientos es un componente importante del enfoque EcoMicro');
  }

}
