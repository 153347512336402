import { Component, OnInit } from '@angular/core';
import * as AOS from 'aos';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
declare var gtag;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'EcoMicro';
  isCompatible = true;

  constructor(
    private ngxService: NgxUiLoaderService,
    private router: Router,
  ) {
    AOS.init();
    const navegator = navigator.userAgent.toLowerCase();
    if (navegator.indexOf('safari') != -1) {
      if (!(navegator.indexOf('chrome') > -1)) {
        this.isCompatible = false;
      } else {
        this.isCompatible = true;
      }
      this.sendRouteGoogleAnalytics();
    }
  }

  ngOnInit() {
    this.startLoading();
    setTimeout(() => {
      this.stopLoading();
    }, 2000);
  }

  private sendRouteGoogleAnalytics() {
    
    const navEndEvents = this.router.events.pipe(
      filter( event => event instanceof NavigationEnd )
    );

    navEndEvents.subscribe( (event: NavigationEnd) =>{
      gtag('config', environment.googleAnalytics,{
        'page_path': event.urlAfterRedirects
      });
    })
  }

  private startLoading() {
    this.ngxService.start();
    this.ngxService.startBackground('do-background-things');
  }

  private stopLoading() {
    this.ngxService.stop();
    this.ngxService.stopBackground('do-background-things');
  }

}


