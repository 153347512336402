<ng-container *ngIf="projectsData.length < 3">
  <div class="spinner-container d-flex justify-content-center">
    <div class="spinner-border text-success" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
</ng-container>

<div class="col-12 col-md-12 mt-3" data-aos="fade-zoom-in"
data-aos-easing="ease-in-back"
data-aos-delay="500"
data-aos-offset="0">
  <owl-carousel-o [options]="customOptionOne">
    <ng-template carouselSlide *ngFor="let project of projectsData">
      <div class="row">
        <div class="col-sm-12 px-md-3 p-sm-3">
          <div class="col-12 card-project-container p-0">
            <div class="row">
              <div class="col-md-12">
                <div class="card" data-background="image" [style.background-image]="'url('+ project.imageProject +')'">
                  <div class="card-body">
                    <div class="container-titles-h6">
                      <h6 class="card-category">{{"("+ project.countryTitle+ ") - "+ project.title }}</h6>
                      <h6 class="type-project">{{project.projectType }}</h6>
                    </div>
                    <div class="card-icon-projects">
                      <img [src]="project.projectIcon" alt="icono" class="nc-icon d-flex mx-auto" style="max-width: 100px;">
                    </div>
                    <div class="ellipsis-card-project" ellipsis>
                      <p class="card-description">{{ project.descriptionProj.summary }}</p>
                    </div>
                    <div class="card-footer">
                      <a href="javascript:void(0)" (click)="content(project)" >
                        <i class="fa fa-book" aria-hidden="true"></i> {{'vermas' | translate}}
                      </a>
                    </div>
                    <div class="flag d-flex justify-content-end">
                      <img [src]="project.countryImage" [alt]="project.countryTitle">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </owl-carousel-o>
</div>
