import { Injectable } from "@angular/core";
import { Apollo, gql } from "apollo-angular";
import { DocumentNode } from "graphql";
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { environment } from '../../../environments/environment'

@Injectable({
    providedIn: "root"
})
export class CountryService {

  rates: any[];
  loading = true;
  error: any;
  options = {
    headers: new HttpHeaders({
      Authorization: 'Basic ' + btoa(`${environment.user}:${environment.pass}`)
    })
  }

  constructor(
    private apollo: Apollo,
    private http: HttpClient) { }

  getCountriesJson(){
    return this.http.get(`${environment.urlApiJson}/ecomicro-api/node/paises`, this.options);
  }

  getCountriesPromise() {
    return new Promise<any>((resolve, reject) => {
      this.http.get(`${environment.urlApiJson}/ecomicro-api/node/paises`, this.options).toPromise()
      .then( res => { resolve(res); },
      msg => reject(msg));
    });
  }

  getTopic(language: string ){
    let topicEnglish = ['All','Mitigation Finance','Adaptation Finance'];
    let topicSpanish = ['Todos','Financiamiento de mitigación','Financiamiento de la adaptación'];

    if (language === "en") return topicEnglish;
    if (language === "es") return topicSpanish;
  }
}
