import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bar-up-marks',
  templateUrl: './bar-up-marks.component.html',
  styleUrls: ['./bar-up-marks.component.scss']
})
export class BarUpMarksComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {}

}
