import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { LibraryModel } from 'src/app/core/models/library.model';
import { CountryService } from 'src/app/core/services/country.service';
import { LibraryService } from 'src/app/core/services/library.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-card-library',
  templateUrl: './card-library.component.html',
  styleUrls: ['./card-library.component.scss']
})
export class CardLibraryComponent implements OnInit {

  libraryData: any[] = [];
  libraryDataJson: any[] = [];
  dataOpen = false;
  data;
  language = '';
  lang = 'en';
  isMobile : boolean = false;
  projectsData: any[];

  inputSearch:string = '';
  selectCoutry:string = '';
  selectTopic:string = '';
  libraryFiltered:LibraryModel[];

  public formulario: UntypedFormGroup;
  public listCountries: Array<string> = [];
  public listCountriesJson: Array<string> = [];
  public listTopics: Array<string> = [];
  public urlBase = environment.urlGraph;

  constructor(
    private libraryService: LibraryService,
    private router: Router,
    private countryService: CountryService,
    private formBuilder: UntypedFormBuilder) {
      
    this.isMobile = (window.innerWidth <= 767);
    this.router.events.subscribe((event: any) => {
      if (typeof event.url !== 'undefined') {
        if (event.url.includes('lang')) {
          this.ngOnInit();
        }
      }
    });
  }

  ngOnInit(): void {
    this.buildForm();
    this.lang = localStorage.getItem('lang');
    this.language = (this.lang === "en") ? 'EN' : 'ES';
    // this.getLibraries();
    this.getCountries();
    this.getTopic();
    this.getLibraryJson();
  }

  private buildForm() {
    this.formulario = this.formBuilder.group({
      description: ['', []],
      country: ['', []],
      topic: ['', []],
    });
  }

  getImagesJson(arr:Array<any>, id:any) {
    let finalimage;
    for (let i = 0; i < arr.length; i++) {
      const element = arr[i];
      if (id === element.id) {
        finalimage = element.attributes.uri.url
      }
    }
    return finalimage;
  }

  async getLibraryJson() {
    let libEN = [];
    let libES = [];
    const libRes = await this.libraryService.getLibraryJson();
    const coRes = await this.countryService.getCountriesPromise();
    for (let i = 0; i < libRes.data.length; i++) {
      const library = libRes.data[i];
      if (library.attributes.langcode === 'en') {
        for (let i = 0; i < coRes.data.length; i++) {
          const country = coRes.data[i];
          if (country.id === library.relationships.field_pais_articulo.data.id) {
            libEN.push({
              libTitle: library.attributes.title,
              libDescription: library.attributes.field_descripcion_library,
              libTypeProj: library.attributes.field_tipo_proyecto,
              libDate: library.attributes.field_fecha_library,
              libImage: `https://dev-ecomicro.pantheonsite.io${this.getImagesJson(libRes.included, library.relationships.field_imagen_library.data.id)}`,
              libPdf: `https://dev-ecomicro.pantheonsite.io${this.getImagesJson(libRes.included, library.relationships.field_pdf_file.data.id)}`,
              libCountryImg: `https://dev-ecomicro.pantheonsite.io${this.getImagesJson(coRes.included, country.relationships.field_bandera.data.id)}`,
              libCountryTilte: country.attributes.title
            });
          } 
        }
      } else if (library.attributes.langcode === 'es') {
        for (let i = 0; i < coRes.data.length; i++) {
          const country = coRes.data[i];
          if (country.id === library.relationships.field_pais_articulo.data.id) {
            libES.push({
              libTitle: library.attributes.title,
              libDescription: library.attributes.field_descripcion_library,
              libTypeProj: library.attributes.field_tipo_proyecto,
              libDate: library.attributes.field_fecha_library,
              libImage: `https://dev-ecomicro.pantheonsite.io${this.getImagesJson(libRes.included, library.relationships.field_imagen_library.data.id)}`,
              libPdf: `https://dev-ecomicro.pantheonsite.io${this.getImagesJson(libRes.included, library.relationships.field_pdf_file.data.id)}`,
              libCountryImg: `https://dev-ecomicro.pantheonsite.io${this.getImagesJson(coRes.included, country.relationships.field_bandera.data.id)}`,
              libCountryTilte: country.attributes.title
            });
          } 
        }
      }
    }

    if (this.language === 'EN') {
      this.libraryDataJson = libEN;
      this.libraryFiltered = libEN;
    } else {
      this.libraryDataJson = libES;
      this.libraryFiltered = libES;
    }
    // console.log('promise service', this.libraryDataJson);
  }

  redirectViewLibrary(item: any) {
    this.libraryService.setLibraryView(item);
    this.router.navigate([`library-view/${item.uuid}`]);
    window.scroll(0, 0);
  }

  searchBy() {
    if (this.inputSearch || this.selectCoutry || this.selectTopic) {
      if (this.inputSearch) {
        this.libraryFiltered = this.libraryDataJson.filter( (lib:LibraryModel) => 
        lib.libTitle.toLowerCase().includes(this.inputSearch.toLowerCase())); 
      }
      if (this.selectCoutry) {
        this.libraryFiltered = this.libraryDataJson.filter( (lib:LibraryModel) => 
        lib.libCountryTilte.toLowerCase().includes(this.selectCoutry.toLowerCase())); 
      }
      if (this.selectTopic) {
        this.libraryFiltered = this.libraryDataJson.filter( (lib:LibraryModel) => 
        lib.libTypeProj.toLowerCase().includes(this.selectTopic.toLowerCase())); 
      }
    } else {
      this.libraryFiltered = this.libraryDataJson;
    }
    
  }

  getTopic() {
    this.listTopics = this.countryService.getTopic(this.lang);
  }

  async getCountries() {
    let countryES = [];
    let countryEN = [];
    const countries = await this.countryService.getCountriesPromise();
    for (let i = 0; i < countries.data.length; i++) {
      const country = countries.data[i];
      if (country.attributes.langcode === 'en') {
        countryEN.push(country.attributes.title)
      } else if (country.attributes.langcode === 'es') {
        countryES.push(country.attributes.title)
      }
    }
    if (this.language === 'EN') {
      this.listCountries = countryEN;
    } else if (this.language === 'ES') {
      this.listCountries = countryES;
    }
    let respCountryFilter = this.crearArrayPaises(this.listCountries);
    this.listCountries = this.ordenarPaises(respCountryFilter);
    this.dataOpen = true;
  }

  crearArrayPaises(data: Array<any>) {
    let newArray = [];
    if (this.lang === "en") newArray.push('All');
    if (this.lang === "es") newArray.push('Todos');
    for (const pais of data) {
      newArray.push(pais);
    }
    return newArray;
  }

  ordenarPaises(data: Array<any>) {
    return data.sort((a, b) => {
      if (a > b) return +1;
      if (a < b) return -1;
      if (a === b) return 0;
    });
  }
}
