<div *ngIf="system.open">
  <div class="wrapper banner-home">
    <div class="page-header section-dark background-libary">
      <div class="filter"></div>
      <div class="content-center">
        <div class="container">


          <div class="d-flex justify-content-between flex-wrap align-content-center">
            <div class="col-12 col-sm-12 col-md-6 col-lg-6 h-100">
              <div class="title-brand mt-5 mb-3">
                <h1 class="banner-title">
                  {{'library' | translate}}
                </h1>
              </div>
            </div>
            <div class="col-12 col-sm-12 col-md-6 col-lg-6 h-100">
              <div class="title-brand-description mt-4">
                <h3 class="description mt-4 text-center">
                  <span>
                    {{'financiamientoclimatico' | translate}}
                  </span>
                </h3>
                <h5>
                  {{'financiamientoclimaticoLibrary' | translate}}
                </h5>
              </div>
            </div>
          </div>


        </div>
      </div>
    </div>
  </div>

  <div class="container-fluid">
    <div class="sect-container">

      <div class="sec-library-img">
        <div class="img-father d-flex justify-content-center align-content-center flex-wrap">
          <img class="img-fluid img card no-transition" [src]="library.fieldImagenLibrary.url" alt="imagen proyecto">
          <div class="sec-date">
            <h5>{{library.fieldFechaLibrary.date | date: 'dd-MM-yyyy'}}</h5>
          </div>
        </div>
      </div>

      <div class="sec-library-text">
        <div class="sec-library-title">
          <h2>{{library.title}}</h2>
        </div>
        <div class="sec-library-items">
          <div class="row">
            <div class="col-lg-3 col-md-6 col-sm-12 col-12">
              <p class="typeProject">
                {{library.fieldTipoProyecto}}
              </p>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-12 col-12">
              <div class="pl-3 row">
                <img class="img-pais" [src]="library.fieldPaisArticulo.entity.fieldBandera.url" alt="img-library">
                <p class="name-pais">{{library.fieldPaisArticulo.entity.title}}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="sec-library-description">
          <div class="text-descripcion" [innerHTML]="library.fieldDescripcionLibrary.processed">
          </div>
        </div>
      </div>
    </div>
  </div>

  <app-footer></app-footer>

</div>
