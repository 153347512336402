import { Injectable } from "@angular/core";
import { Apollo, gql } from "apollo-angular";
import { DocumentNode } from "graphql";
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { environment } from '../../../environments/environment'
import { tap } from "rxjs/operators";

@Injectable({
  providedIn: "root"
})
export class ProjectsService {

  rates: any[];
  loading = true;
  error: any;
  project = {};

  constructor(
    private apollo: Apollo,
    private http:HttpClient) { }

  public singleProject: object;
  public allProjects:any;

  options = {
    headers: new HttpHeaders({
      Authorization: 'Basic ' + btoa(`${environment.user}:${environment.pass}`)
    })
  }

  getProjectsPromise(projectId?:any) {
    return new Promise<any>((resolve, reject) => {
      if (projectId) {
        this.http.get(`${environment.urlApiJson}/ecomicro-api/node/proyectos?filter[id]=${projectId}`, this.options).toPromise()
        .then( res => { resolve(res) },
        msg => { reject(msg) });
      } else {
        this.http.get(`${environment.urlApiJson}/ecomicro-api/node/proyectos`, this.options).toPromise()
        .then( res => { resolve(res) },
        msg => { reject(msg) });
      }
    })
  }

  getIconsPromise() {
    return new Promise<any>((resolve, reject) => {
      this.http.get(`${environment.urlApiJson}/ecomicro-api/taxonomy_term/iconos`, this.options).toPromise()
      .then( res => resolve(res),
      msg => reject(msg));
    });
  }
}
