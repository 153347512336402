<div class="col-12 card-beneficiaries-container p-0">
  <div class="row">
    <div class="col-md-12">
      <div class="card card-profile card-plain" (click)="redirectTo(redirect)">
        <div class="card-img-top">
          <img class="img" src="{{images}}" [alt]="name" />
        </div>
        <div class="card-body">
          <h4 class="card-title">{{name}}</h4>
          <p class="card-category">{{description | translate}}</p>
        </div>
      </div>
    </div>
  </div>
</div>
