import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import * as Mapboxgl from 'mapbox-gl';
import { HomeService } from 'src/app/core/services/home.service';

import * as d3 from 'd3';
import d3Tip from 'd3-tip';
import * as topojson from 'topojson';
import countriesNames from '../../../assets/content/countries_names.json';
import countriesNamesEs from '../../../assets/content/countries_names_es.json';
import { LibraryService } from 'src/app/core/services/library.service';
import { NewsService } from 'src/app/core/services/news.service';
import { Router } from '@angular/router';
import { CountryService } from 'src/app/core/services/country.service';
import { SeoService } from '../../core/services/seo.service';
import { Title } from '@angular/platform-browser';
import { ProjectsService } from 'src/app/core/services/projects.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, AfterViewInit, OnDestroy {
  public static SCALE_ZOOM = 50;
  public static SCREEN_MEDIUM = -200;
  public static SCREEN_MEDIUM_TWO1440 = -300;
  public static SCREEN_MEDIUM_TWO1440S = -200;
  public static SCREEN_BIG_ESPECIAL1680 = -300;
  public static SCREEN_BIG_ESPECIAL1680S = -200;


  // CENTRO AMERIC FOCUS
  // Used to initial map size in normal screen
  public static SCREEN_BIG_ESPECIAL1920 = -350;
  // Used to initial map focus in normal screen
  public static SCREEN_FOCUS_BIG_ESPECIAL = 130;
  public static SCREEN_FOCUS_BIG_ESPECIAL1680 = 200;
  public static SCREEN_FOCUS_BIG_ESPECIAL1680S = -105;

  public static SCREEN_FOCUS_BIG_ESPECIAL1440 = 200;
  public static SCREEN_FOCUS_BIG_ESPECIAL1440S = -200;


  // Used to make rigth of size support
  public static SCREEN_SMALL_ESPECIAL_1920 = 550;

  // SUR AMERIC FOCUS
  // Used to initial map size in normal screen
  public static SCREEN_BIG_ESPECIAL1920S = -250;
  // Used to initial map focus in normal screen
  public static SCREEN_FOCUS_BIG_ESPECIALS = -150;
  // Used to make rigth of size support
  public static SCREEN_SMALL_ESPECIAL_1920S = -30;
  public static SCREEN_SMALL_ESPECIAL_1920SD = 2.5;

  // widht 1920 right eje X
  public static SCREEN_BIG_ESPECIALTWO = -250;
  public static SCREEN_RIGTH_SPECIAL = 0;

  public static SCREEN_SMALL_MEDIUM = -130;
  public static SCREEN_FOCUS = -180;
  public static SCREEN_FOCUS_ESPECIAL1366 = 100;
  public static SCREEN_FOCUS_ESPECIAL1366S = -170;


  public static SCREEN_SMALL_RIGTH_ESPECIAL1240 = 700;
  public static SCREEN_SMALL_RIGTH_ESPECIAL1240S = -150;
  public static SCREEN_SMALL_ESPECIAL1220 = -400;
  public static SCREEN_SMALL_ESPECIAL1220S = -280;
  public static SCREEN_SMALL_ESPECIAL1366 = -400;
  public static SCREEN_SMALL_ESPECIAL1366S = -250;
  public static SCREEN_SMALL_ESPECIAL_TWO1366 = 600;
  public static SCREEN_SMALL_ESPECIAL_TWO1366S = -100;

  public static SCREEN_SMALL_ESPECIAL_THREE1440 = 650;
  public static SCREEN_SMALL_ESPECIAL_THREE1440S = -150;
  public static SCREEN_SMALL_ESPECIAL_FOUR1680 = 650;
  public static SCREEN_SMALL_ESPECIAL_FOUR1680S = -50;


  public static SCREEN_SMALL = -290;
  public static SCREEN_SMALL_MOVIL = -350;
  public static SCREEN_SMALL_MOVILS = -300;
  public static SCREEN_SMALL_RIGTH = -290;
  public static SCREEN_SMALL_RIGTH_MOVIL = -200;
  public static SCREEN_SMALL_RIGTH_MOVIL400 = 250;
  public static SCREEN_SMALL_RIGTH_MOVIL400S = -150;

  // scale to  width > 1920
  public static SCREEN_FOCUS_SMALL1024 = 280;

  public static SCREEN_FOCUS_SMALL1024S = -180;

  public static SCREEN_FOCUS_SMALLMOVIL = -10;
  public static SCREEN_FOCUS_SMALLMOVILS = -260;


  public static SCALE_GLOBAL_FLEX = 0.3298769776932243;

  public static MAX_ZOOM = 0.3298769776932243;
  public static MAX_ZOOM_VALIDATION = 0.4298769776932243;
  public static MIN_ZOOM = 168.89701257893157;
  public static SCALE_INITIAL_AMERICA = 450;
  public static SCALE_AMERICA_TRANSLATE_X = 1000;
  public static SCALE_AMERICA_TRANSLATE_Y = 600;
  public static SCALE_INITIAL_GLOBAL = {
    initial: 150,
    flex: 0.4
  };
  public static SCALE_GLOBAL_TRANSLATE_X = {
    initial: 600,
    flex: 211.94743136956208,
    flexZoom: 136.5162420879724
  };
  public static SCALE_GLOBAL_TRANSLATE_Y = {
    initial: 600,
    flex: 418.3489569159362,
    flexZoom: 371.77766719392594
  };
  dataOpen = false;
  data;
  countries = [];

  customOptionOne: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: true,
    nav: false,
    navSpeed: 700,
    dotsSpeed: 500,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      740: {
        items: 2,
      },
      940: {
        items: 3,
      },
    },

  };
  
  customOptionOneCarousel: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: true,
    nav: false,
    navSpeed: 700,
    autoplay:true,
    autoplayTimeout:3000,
    autoplayHoverPause:true,
    dotsSpeed: 3000,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      740: {
        items: 2,
      },
      940: {
        items: 3,
      },
    },

  };


  cards = [
    {
      img: `../../../assets/imagenesEcomicro/caribbean.png`,
      titulo: 'CARIBBEAN',
      url: '/caribbean',
      descripcion: 'EcoMicro pilot projects are underway in seven English-speaking Caribbean countries. In a region where resilience must always be top of mind, local banks, development agencies, credit unions and other partners are creating new financial tools to help their customers meet their energy needs and adapt to climate change and other challenges.'
    },
    {
      img: `../../../assets/imagenesEcomicro/latin_america.png`,
      titulo: 'LATIN AMERICA',
      url: '/latin-america',
      descripcion: 'In recent years, EcoMicro has implemented pilot projects in 11 Latin American countries. These ventures start out small but often go on to reach a larger scale. Today, EcoMicro is working with an increasingly diverse set of partners—not just financial institutions but business cooperatives and anchor companies—to provide innovative green finance options.'
    }
  ];

  areasFinanzas = [
    {
      img: '../../../assets/images/iconos-ecomicro/grupo-5998.png',
      descripcion: 'Enabling access to Renewable Energy'
    }, {
      img: '../../../assets/images/iconos-ecomicro/grupo-5999.png',
      descripcion: 'Incorporating Energy Efficiency'
    }, {
      img: '../../../assets/images/iconos-ecomicro/grupo-6079.png',
      descripcion: 'Facilitating Climate Change Adaptation'
    }
  ];

  newSletters = [];

  beneficiariosJson:any = [];
  newsLetttersJson:any = [];

  beneficiariesPeople: any[];
  libraries: any[];
  map: Mapboxgl.Map;

  allianceEvents: any;

  basic = true;
  public zoomManagment = d3.zoomIdentity.k;
  public mapOpen = false;

  public SVG = null;
  public G = null;
  public ZOOM = null;
  public PATH = null;
  public WIDTH = null;
  public HEIGTH = null;

  valor1 = 28;
  valor2 = 13;
  valor3 = 12;
  valor4 = 18;

  footerTrue = false;

  public scaleZoomInitial = 0;
  lang = 'en';
  language = '';
  screenWidth = 0;
  constructor(
    private homeService: HomeService,
    private libraryService: LibraryService,
    private newsService: NewsService,
    private router: Router,
    private countryService: CountryService,
    private seoService: SeoService,
    private title: Title,
    private projectService: ProjectsService
  ) {
    this.lang = localStorage.getItem('lang');
    this.language = (this.lang === "en") ? 'EN' : 'ES';
    // this.countryService.getCountry('', this.language, 100, 0, (data, total) => {
    //   this.countries = data;
    // });
    this.screenWidth = window.innerWidth;
    this.router.events.subscribe((event: any) => {
      if (typeof event.url !== 'undefined') {
        if (event.url.includes('lang')) {
          this.ngOnInit();
        }
      }
    });
  }

  ngAfterViewInit(): void {
    this.optionsMap(this, '2');
  }

  ngOnInit(): void {
    this.lang = localStorage.getItem('lang');
    this.language = (this.lang === "en") ? 'EN' : 'ES';
    this.flags();
    this.getBeneficiariosJson();
    this.getNewsletterJson();
  }

  getImagesJson(arr:Array<any>, id:any) {
    let finalimage;
    for (let i = 0; i < arr.length; i++) {
      const element = arr[i];
      if (id === element.id) {
        finalimage = element.attributes.uri.url
      }
    }
    return finalimage;
  }

  getBeneficiariosJson() {
    this.homeService.getBeneficiarios().subscribe( (res:any) => {
      let benEN = [];
      let benES = [];

      for (let i = 0; i < res.data.length; i++) {
        const element = res.data[i];

        if (element.attributes.langcode === 'en') {
          benEN.push({
            title: element.attributes.title,
            empresaCiudad: element.attributes.field_empresa_ciudad,
            linkBeneficiario: element.attributes.field_link_beneficiario.uri,
            descripcionBeneficiario: element.attributes.field_descripcion_beneficiario.summary,
            imagePreview: `https://dev-ecomicro.pantheonsite.io${this.getImagesJson(res.included, element.relationships.field_imagen_preview.data.id)}`
          });
        } else if (element.attributes.langcode === 'es') {
          benES.push({
            title: element.attributes.title,
            empresaCiudad: element.attributes.field_empresa_ciudad,
            linkBeneficiario: element.attributes.field_link_beneficiario.uri,
            descripcionBeneficiario: element.attributes.field_descripcion_beneficiario.summary,
            imagePreview: `https://dev-ecomicro.pantheonsite.io${this.getImagesJson(res.included, element.relationships.field_imagen_preview.data.id)}`
          });
        }
      }
      
      if (this.language === 'EN') {
        this.beneficiariosJson = benEN
      } else {
        this.beneficiariosJson = benES
      }      
    });
  }

  getNewsletterJson() {
    this.newsService.getNewsletters().subscribe( (res:any) => {
      let newsEN = [];
      let newsES = [];

      for (let i = 0; i < res.data.length; i++) {
        const news = res.data[i];
        
        if (news.attributes.langcode === 'en') {
          newsEN.push({
            id: news.id,
            title: news.attributes.title,
            summary: news.attributes.field_descripcion_boletin.summary,
            ordenBoletin: news.attributes.field_orden_boletin,
            year: news.attributes.field_anios_boletin,
            status: news.attributes.status,
            previewImage: `https://dev-ecomicro.pantheonsite.io${this.getImagesJson(res.included, news.relationships.field_imagen_preview_boletin.data.id)}`
          });
        } else if (news.attributes.langcode === 'es') {
          newsES.push({
            id: news.id,
            title: news.attributes.title,
            summary: news.attributes.field_descripcion_boletin.summary,
            ordenBoletin: news.attributes.field_orden_boletin,
            year: news.attributes.field_anios_boletin,
            status: news.attributes.status,
            previewImage: `https://dev-ecomicro.pantheonsite.io${this.getImagesJson(res.included, news.relationships.field_imagen_preview_boletin.data.id)}`
          });
        }
      }

      if (this.language === 'EN') {
        this.newsLetttersJson = newsEN;
      } else {
        this.newsLetttersJson = newsES;
      }

      if (this.newsLetttersJson.length > 3) {
        const shortArr = this.newsLetttersJson.slice(0, 3);
        this.newsLetttersJson = shortArr;
      }
    });
  }

  orderBullets(data: Array<any>) {
    data.sort(function (a, b) {
      if (a.fieldAniosBoletin < b.fieldAniosBoletin) return +1;
      if (a.fieldAniosBoletin > b.fieldAniosBoletin) return -1;
      if (a.fieldAniosBoletin === b.fieldAniosBoletin) {
        if (a.fieldOrdenBoletin < b.fieldOrdenBoletin) return +1;
        if (a.fieldOrdenBoletin > b.fieldOrdenBoletin) return -1;
      }
    })
  }

  cutBullets(data: Array<any>){
    let newArray = [];
    for (let id = 0; id < data.length; id++) {
      if (id <= 2) {
        const element = data[id];
        newArray.push(element);
      } else {
        break;
      }
    }

    return newArray;
  }

  ejecutarGrafica() {
    let datos = [2, 4, 6, 8, 10, 20, 50, 80, 100];
    d3.select(".barras")
      .selectAll("div")
      .data(datos)
      .enter().append('div')
      .attr('class', 'barra-child')
      .style("height", function (d) {
        return `${d}px`;
      });
  }

  public getCountryName(countryId) {
    if (this.language === 'ES') {
      return countriesNamesEs.filter(x => x.id === countryId)[0].name;
    } else {
      return countriesNames.filter(x => x.id === countryId)[0].name;
    }
  }

  goToProjectByCountry(title): void {
    this.router.navigate([`/projects/${title}`]);
  }

  sendNew(news: object, route: string) {
    window.scroll(0, 0);
    this.router.navigate([`/${route}`]);
  }

  async getMaps() {
    let countriesMap = [];
    const activeProj = await this.projectService.getProjectsPromise();
    const countries = await this.countryService.getCountriesPromise();

    for (let i = 0; i < activeProj.data.length; i++) {
      const proj = activeProj.data[i];
      for (let i = 0; i < countries.data.length; i++) {
        const country = countries.data[i];
        if (proj.relationships.field_pais.data.id === country.id) {
          countriesMap.push(country.attributes.title);
        }        
      }
    }
    return countriesMap.filter((item, i) => countriesMap.indexOf(item) === i);
  }



  getLang(): string {
    return (localStorage.getItem('lang') === null || localStorage.getItem('lang') === 'null') ?
     'es' : localStorage.getItem('lang');
  }

  getColorCountry(active) {
    return (!active) ?  '#ccc' : '#8abe26';
  }

  linkCountryToProyectIsActive(dataActive, id) {
    const idString = id.toString();
    return (dataActive.filter((x) => x === idString).length > 0);
  }

  private optionsMap(parent, type: string): void {
    let sizer = this.getSize(window.innerWidth, type);
    let sizerRigth = this.getSizeRigth(sizer, window.innerWidth, type);
    let scaler = this.getScale(sizer, window.innerWidth, type);
    let width = window.innerWidth - (window.innerWidth / 2);
    let height = window.innerHeight - (window.innerHeight / ((type === '1') ? 7 :
    (width > 510) ? HomeComponent.SCREEN_SMALL_ESPECIAL_1920SD : 1.5));
    let active = d3.select(null);

    let projection = d3.geoMercator()
      .scale(scaler + ((this.basic) ?
        HomeComponent.SCALE_INITIAL_AMERICA +
        (-this.scaleZoomInitial) : HomeComponent.SCALE_INITIAL_GLOBAL.initial + (-this.scaleZoomInitial)))
      .translate(([
        (this.basic) ?
          HomeComponent.SCALE_AMERICA_TRANSLATE_X + (sizer) + (sizerRigth) :
          HomeComponent.SCALE_GLOBAL_TRANSLATE_X.initial + (sizer) + (sizerRigth),
        height - (
          (this.basic) ?
            HomeComponent.SCALE_AMERICA_TRANSLATE_Y + (sizer) :
            HomeComponent.SCALE_GLOBAL_TRANSLATE_Y.initial + (sizer)
        )
      ]));

    let zoom = d3.zoom()
      .scaleExtent([HomeComponent.MAX_ZOOM, HomeComponent.MIN_ZOOM])
      .on('zoom', zoomed);
    let path = d3.geoPath().projection(projection);

    let svg = d3.select((type === '1') ? '#mapInitiatives' : '#mapInitiativesS').append('svg')
      .attr('width', width)
      .attr('height', height)
    .on('click', stopped, true);

    svg.append('rect')
      .attr('class', 'background')
    .on('click', reset);

    let g = svg.append('g');

    const tip = d3Tip()
      .attr('class', 'd3-tip card-initiatives-tip')
      .direction((d) => {
        const name = countriesNames.filter((x) => x.id === d.id.toString())[0].name;
        if (name === 'Antarctica') { return 'n'; }
        // Americas
        if (name === 'Greenland') { return 's'; }
        if (name === 'Canada') { return 'e'; }
        if (name === 'United States Of America') { return 's'; }
        if (name === 'Mexico') { return 'e'; }
        // Europe
        if (name === 'Iceland') { return 's'; }
        if (name === 'Norway') { return 's'; }
        if (name === 'Sweden') { return 's'; }
        if (name === 'Finland') { return 's'; }
        if (name === 'Russia') { return 'w'; }
        // Asia
        if (name === 'China') { return 'w'; }
        if (name === 'Japan') { return 's'; }
        // Oceania
        if (name === 'Indonesia') { return 'w'; }
        if (name === 'Papua New Guinea') { return 'w'; }
        if (name === 'Australia') { return 'w'; }
        if (name === 'New Zealand') { return 'w'; }
        // otherwise if not specified
        return 'n';
      })
      .offset((d) => {
        const name = countriesNames.filter((x) => x.id === d.id.toString())[0].name;
        // [top, left]
        if (name === 'Antarctica') { return [0, 0]; }
        // Americas
        if (name === 'Greenland') { return [10, -10]; }
        if (name === 'Canada') { return [24, -28]; }
        if (name === 'United States Of America') { return [-100, -250]; }

        if (name === 'Mexico') { return [12, 10]; }
        // Europe
        if (name === 'Iceland') { return [15, 0]; }
        if (name === 'Norway') { return [10, -28]; }
        if (name === 'Sweden') { return [10, -8]; }
        if (name === 'Finland') { return [10, 0]; }
        if (name === 'France') { return [-9, 66]; }
        if (name === 'Italy') { return [-8, -6]; }
        if (name === 'Russia') { return [5, 385]; }
        // Africa
        if (name === 'Madagascar') { return [-10, 10]; }
        // Asia
        if (name === 'China') { return [-16, -8]; }
        if (name === 'Mongolia') { return [-5, 0]; }
        if (name === 'Pakistan') { return [-10, 13]; }
        if (name === 'India') { return [-11, -18]; }
        if (name === 'Nepal') { return [-8, 1]; }
        if (name === 'Myanmar') { return [-12, 0]; }
        if (name === 'Laos') { return [-12, -8]; }
        if (name === 'Vietnam') { return [-12, -4]; }
        if (name === 'Japan') { return [5, 5]; }
        // Oceania
        if (name === 'Indonesia') { return [0, -5]; }
        if (name === 'Papua New Guinea') { return [-5, -10]; }
        if (name === 'Australia') { return [-15, 0]; }
        if (name === 'New Zealand') { return [-15, 0]; }
        // otherwise if not specified
        return [-10, 0];
      })
      .html((d) => {
        const country = this.getCountryName(d['id'].toString());
        return `
          <div class="content toolTip">
            <div class="row">
              <div class="col-12 d-flex">
                <div class="col-12">
                  <h3 class="title-hover-map">${country}</h3>
                </div>
              </div>
            </div>
          </div>`;
      });
    svg.call(zoom); // delete this line to disable free zooming
    svg.call(tip);
    d3.json('https://gist.githubusercontent.com/mbostock/4090846/raw/d534aba169207548a8a3d670c9c2cc719ff05c47/world-50m.json')
      .then((world) => {
        const actives = parent.getMaps();
        actives.then((xActive)  => {
          
          world.objects.countries.geometries = world.objects.countries.geometries.filter(
            (country) =>
              (countriesNames.filter((n) => n.id.toString() === country.id.toString()).length > 0)
          );

          const activeMapsLogic = xActive;
          let dataToSearch = [];
          activeMapsLogic.forEach(element => {
            if (countriesNames.filter((x) => x.name === element).length > 0) {
              dataToSearch.push(countriesNames.filter((x) => x.name === element)[0].id);
            }
          });
          g.selectAll('path')
            .data(topojson.feature(world, world.objects.countries).features)
            .enter().append('path')
            .attr('d', path)
            .attr('class', 'feature participant')
            .style('fill', (d) => {
              return parent.getColorCountry(dataToSearch.filter((x) => d['id'].toString() === x).length > 0);
            }).on('mouseover', function (d) {
              tip.show(d, this);
              d3.select(this)
                .style('opacity', 1)
                .style('stroke-width', 3);
            })
            .on('mouseout', function (d) {
              tip.hide(d, this);
              d3.select(this)
                .style('opacity', 0.8)
                .style('stroke-width', 0.3);
            })
            .on('click', clicked);

          g.append('path')
            // tslint:disable-next-line:arrow-return-shorthand
            .datum(topojson.mesh(world, world.objects.countries, (a, b) => { return a !== b; }))
            .attr('class', 'mesh')
            .attr('d', path);

          function clicked(d) {
            const linkApproved = parent.linkCountryToProyectIsActive(dataToSearch, d.id);
            if (typeof d.id !== 'undefined' && linkApproved) {
              parent.goToProjectByCountry(parent.getCountryName(d.id.toString()));
            }
          }
        });
      });

    function reset() {
      active.classed('active', false);
      active = d3.select(null);
    }

    function zoomed() {
      g.style('stroke-width', 1.5 / d3.event.transform.k + 'px');
      parent.transformMap = d3.event.transform;
      g.attr('transform', d3.event.transform); // updated for d3 v4
    }

    function stopped() {
      if (d3.event.defaultPrevented) d3.event.stopPropagation();
    }

    this.SVG = svg;
    this.G = g;
    this.ZOOM = zoom;
    this.PATH = path;
    this.WIDTH = width;
    this.HEIGTH = height;
    this.mapOpen = true;
  }

  private getSize(width: number, type: string): number {
    let sizeMap = 0;
    if (width > 1024 && width <= 1920) {
      if (width > 1024 && width <= 1264) {
        sizeMap = (type === '1') ? HomeComponent.SCREEN_SMALL_ESPECIAL1220 : HomeComponent.SCREEN_SMALL_ESPECIAL1220S;
      } else if (width > 1264 && width <= 1370) {
        sizeMap = (type === '1') ?
          HomeComponent.SCREEN_SMALL_ESPECIAL1366 : HomeComponent.SCREEN_SMALL_ESPECIAL1366S;
      } else if (width > 1366 && width <= 1450) {
        sizeMap = (type === '1') ?
          HomeComponent.SCREEN_MEDIUM_TWO1440 : HomeComponent.SCREEN_MEDIUM_TWO1440S;
      } else if (width > 1450 && width <= 1680) {
        sizeMap = (type === '1') ?
          HomeComponent.SCREEN_BIG_ESPECIAL1680 : HomeComponent.SCREEN_BIG_ESPECIAL1680S;
      }
      else if (width > 1680 && width <= 1920) {
        // normal screen
        sizeMap = (type === '1') ?
          HomeComponent.SCREEN_BIG_ESPECIAL1920 : HomeComponent.SCREEN_BIG_ESPECIAL1920S;
      }
      else {
        sizeMap = HomeComponent.SCREEN_MEDIUM;
      }
    } else {
      if (width > 319 && width <= 900) {
        sizeMap = (type === '1') ?
          HomeComponent.SCREEN_SMALL_MOVIL : HomeComponent.SCREEN_SMALL_MOVILS;
      }
      if (width > 900 && width <= 1024) {
        sizeMap = HomeComponent.SCREEN_SMALL;
      } else {
        if (width > 1920) {
          sizeMap = HomeComponent.SCREEN_BIG_ESPECIALTWO;
        }
      }
    }
    return sizeMap;
  }

  private getSizeRigth(sizer: number, width: number, type: string): number {
    let size = 0;
    if (sizer) {
      if (width > 1024 && width <= 1264) {
        size = (type === '1') ? HomeComponent.SCREEN_SMALL_RIGTH_ESPECIAL1240 : HomeComponent.SCREEN_SMALL_RIGTH_ESPECIAL1240S;
      } else if (width > 1264 && width <= 1370) {
        size = (type === '1') ? HomeComponent.SCREEN_SMALL_ESPECIAL_TWO1366 : HomeComponent.SCREEN_SMALL_ESPECIAL_TWO1366S;
      }
      else if (width > 1367 && width <= 1480) {
        size = (type === '1') ? HomeComponent.SCREEN_SMALL_ESPECIAL_THREE1440 : HomeComponent.SCREEN_SMALL_ESPECIAL_THREE1440S;
      } else if (width > 1480 && width <= 1680) {
        size = (type === '1') ? HomeComponent.SCREEN_SMALL_ESPECIAL_FOUR1680 : HomeComponent.SCREEN_SMALL_ESPECIAL_FOUR1680S;
      } else if (width > 1680 && width <= 1920) {
        size = (type === '1') ? HomeComponent.SCREEN_SMALL_ESPECIAL_1920 : HomeComponent.SCREEN_SMALL_ESPECIAL_1920S;
      } else {
        size = HomeComponent.SCREEN_SMALL_RIGTH;
        if (width <= 375) {
          size = HomeComponent.SCREEN_SMALL_RIGTH_MOVIL;
        }
        if (width > 375 && width <= 900) {
          size = (type === '1') ? HomeComponent.SCREEN_SMALL_RIGTH_MOVIL400 : HomeComponent.SCREEN_SMALL_RIGTH_MOVIL400S;
        }
        if (width > 1920) {
          size = HomeComponent.SCREEN_RIGTH_SPECIAL;
        }
      }
    }
    return size;
  }

  private getScale(sizer: number, width: number, type: string): number {
    let scale;
    if (width > 1024 && width <= 1264) {
      scale = (type === '1') ? HomeComponent.SCREEN_FOCUS_SMALL1024 : HomeComponent.SCREEN_FOCUS_SMALL1024S;
    } else if (width > 1264 && width <= 1367) {
      scale = (type === '1') ? HomeComponent.SCREEN_FOCUS_ESPECIAL1366 : HomeComponent.SCREEN_FOCUS_ESPECIAL1366S;
    } else if (width > 1420 && width <= 1680) {
      scale = (type === '1') ? HomeComponent.SCREEN_FOCUS_BIG_ESPECIAL1440 : HomeComponent.SCREEN_FOCUS_BIG_ESPECIAL1440S;
    } else if (width > 1680 && width <= 1920) {
      // normal screen case
      scale = (type === '1') ?
        HomeComponent.SCREEN_FOCUS_BIG_ESPECIAL : HomeComponent.SCREEN_FOCUS_BIG_ESPECIALS;
    } else {
      scale = HomeComponent.SCREEN_SMALL_RIGTH;
      if (width <= 375) {
        scale = (type === '1') ? HomeComponent.SCREEN_FOCUS_SMALL1024 : HomeComponent.SCREEN_FOCUS_SMALL1024S;
      }
      if (width > 375 && width <= 500) {
        scale = (type === '1') ? HomeComponent.SCREEN_FOCUS_SMALLMOVIL : HomeComponent.SCREEN_FOCUS_SMALLMOVILS;
      }
      if (width > 1920) {
        scale = (type === '1') ? HomeComponent.SCREEN_FOCUS_SMALL1024 : HomeComponent.SCREEN_FOCUS_SMALL1024S;
      }
    }
    return scale;
  }

  basicFunction(_basic: boolean): void {
    this.basic = _basic;
    this.zoomManagment = 1;
    if (this.basic) {
      this.SVG.transition()
        .duration(750)
        .call(this.ZOOM.transform, d3.zoomIdentity);
    } else {
      this.SVG.transition()
        .duration(750)
        .call(
          this.ZOOM.transform,
          d3.zoomIdentity.scale(HomeComponent.SCALE_INITIAL_GLOBAL.flex)
            .translate(
              HomeComponent.SCALE_GLOBAL_TRANSLATE_X.flex,
              HomeComponent.SCALE_GLOBAL_TRANSLATE_Y.flex
            )
        );
    }
  }

  redirectPage(route: string) {
    window.scroll(0, 0);
    this.router.navigate([`/${route}`]);
  }

  ngOnDestroy(): void {
    document.querySelector('.card-initiatives-tip').remove();
  }

  footerCounter(){
    this.footerTrue = true;
  }

  flags() {
    this.seoService.generateFlags({
      title: 'EcoMicro',
      site_name: 'EcoMicro',
      description: 'EcoMicro Trabajamos con socios intermediarios financieros en toda América Latina y el Caribe, para ayudarlos a estar mejor equipados para proporcionar financiamiento verde. El objetivo es aumentar el acceso a productos y servicios de energía renovable y eficiencia energética y para promover la adaptación al cambio climático.',
      slug: 'home',
      image: 'https://ecomicro.azureedge.net/assets/imagenesEcomicro/fondo_banner_slider.png'
    });
    this.title.setTitle('EcoMicro');
  }

}
