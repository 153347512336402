<div class="container-fluid-form">
  <form autocomplete="off" [formGroup]="formulario">
    <div class="search col-12 col-md-12">
      <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-12">
          <div class="row">
            <div class="col-12 margin-desktop">
              <div class="row">
                <kendo-textbox-container class="display-cont">
                  <input [(ngModel)]="inputSearch" kendoTextBox class="searchTerm" style="margin-bottom: 9px;" id="description"
                    formControlName="description" (keyup)="searchBy()" [placeholder]="'termSearch' | translate" />
                  <button type="submit" class="searchButton">
                    <em class="fa fa-search"></em>
                  </button>
                </kendo-textbox-container>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-6 col-md-6 col-sm-12 col-12">
          <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-12 col-12 m-mobile">
              <label class="col-3 col-sm-3 col-md-12">{{'countries' | translate}}</label>
              <kendo-combobox class="col-9 col-sm-9 col-md-12" [data]="listCountries"
                [placeholder]="'searchCountry' | translate" id="country" formControlName="country"
                [(ngModel)]="selectCoutry" (valueChange)="searchBy()"></kendo-combobox>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-12 m-mobile">
              <label class="col-3 col-sm-3 col-md-12">{{'topic' | translate}}</label>
              <kendo-combobox class="col-9 col-sm-9 col-md-12" [data]="listTopics"
                [placeholder]="'searchTopic' | translate" id="topic" formControlName="topic"
                [(ngModel)]="selectTopic" (valueChange)="searchBy()"></kendo-combobox>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>

<div *ngFor="let item of libraryFiltered; let i = index">
  <div class="container-fluid" [ngClass]="{'back-gray': i % 2 === 0 }" *ngIf="!this.isMobile">
    <div class="card card-plain card-blog" *ngIf="i % 2 === 0">
      <div class="container">
        <div class="row">
          <div class="col-md-4">
            <div class="card-image" data-aos="fade-right" data-aos-offset="200" data-aos-easing="ease-in-sine"
              data-aos-duration="600">
              <img class="img img-fluid" [src]="item.libImage" [alt]="item.libTitle" />
            </div>
          </div>
          <div class="col-md-8">
            <div class="card-body">
              <h2 class="card-title">
                {{ item.libTitle}}
              </h2>
              <p class="card-description">
                {{ item.libDescription.summary }}
              </p>
              <br>
              <div class="row">
                <div class="col-6 col-md-6 col-lg-6">
                  <p class="typeProject">
                    {{ item.libTypeProj }}
                  </p>
                </div>
                <div class="col-6 col-md-6 col-lg-6" *ngIf="item.libCountryImg !== null && item.libCountryImg !== '' ">
                  <div class="row">
                    <img class="img-pais" [src]="item.libCountryImg" alt="pais">
                    <p class="name-pais">
                      {{item.libCountryTilte}}
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-12">
                <div class="row">
                  <div class="col-5 col-md-6 p-0">
                    <p class="card-date">
                      {{ item.libDate | formatDate}}
                    </p>
                  </div>
                  <div class=" col-7 col-md-6" *ngIf="item.fieldPdfFile !== null && item.fieldPdfFile !== '' ">
                    <p class="read">
                      <a href='{{item.libPdf}}' target="_blank" rel="noopener noreferrer"> Read More</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12">
      <div class="card card-plain card-blog" *ngIf="i % 2 !== 0">
        <div class="container">
          <div class="row">
            <div class="col-md-8">
              <div class="card-body">
                <h2 class="card-title">
                  {{ item.libTitle}}
                </h2>
                <p class="card-description">
                  {{ item.libDescription.summary }}
                </p>
                <br>
                <div class="row">
                  <div class="col-6 col-md-6 col-lg-6">
                    <p class="typeProject">
                      {{ item.libTypeProj }}
                    </p>
                  </div>
                  <div class="col-6 col-md-6 col-lg-6" *ngIf="item.libCountryImg !== null && item.libCountryImg !== '' ">
                    <div class="row">
                      <img class="img-pais" [src]="item.libCountryImg" alt="pais">
                      <p class="name-pais">
                        {{item.libCountryTilte}}
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <div class="row">
                    <div class="col-5 col-md-6 p-0">
                      <p class="card-date">
                        {{ item.libDate | date: 'dd-MM-yyyy'}}
                      </p>
                    </div>
                    <div class=" col-7 col-md-6" *ngIf="item.libPdf !== null && item.libPdf !== '' ">
                      <p class="read">
                        <a href='{{item.libPdf}}' target="_blank" rel="noopener noreferrer"> Read More</a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="card-image" data-aos="fade-left" data-aos-offset="300" data-aos-easing="ease-in-sine"
                data-aos-duration="600">
                <img class="img img-fluid" [src]="item.libImage" [alt]="item.libTitle" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container-fluid" *ngIf="this.isMobile">
    <div class="card card-plain card-blog">
      <div class="container">
        <div class="row">
          <div class="col-md-4">
            <div class="card-image" data-aos="fade-right" data-aos-offset="200" data-aos-easing="ease-in-sine"
              data-aos-duration="600">
              <img class="img img-fluid" [src]="item.libImage" [alt]="item.libTitle" />
            </div>
          </div>
          <div class="col-md-8">
            <div class="card-body">
              <h2 class="card-title">
                {{ item.libTitle}}
              </h2>
              <p class="card-description">
                {{ item.libDescription.summary }}
              </p>
              <br>
              <div class="row">
                <div class="col-6 col-md-6 col-lg-6">
                  <p class="typeProject">
                    {{ item.libTypeProj }}
                  </p>
                </div>
                <div class="col-6 col-md-6 col-lg-6">
                  <div class="row">
                    <img class="img-pais" [src]="item.libCountryImg" alt="pais">
                    <p class="name-pais">
                      {{item.libCountryTilte}}
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-12">
                <div class="row">
                  <div class="col-5 col-md-6 p-0">
                    <p class="card-date">
                      {{ item.libDate | formatDate}}
                    </p>
                  </div>
                  <div class=" col-7 col-md-6" *ngIf="item.libPdf !== null && item.libPdf !== '' ">
                    <p class="read">
                      <a href='{{item.libPdf}}' target="_blank" rel="noopener noreferrer"> Read More</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
