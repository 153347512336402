import { Component, OnInit, ElementRef } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.scss']
})
export class NavMenuComponent implements OnInit {

  private toggleButton: any;
  private toggleButtonScroll: any;

  private sidebarVisible: boolean;
  public langVisible: boolean;

  defaultSpanish = false;
  activeLang = 'en';

  constructor(public location: Location,
              private element: ElementRef,
              private router: Router,
              private translate: TranslateService,
              ) {
                localStorage.setItem('lang', this.activeLang);
                translate.setDefaultLang(this.activeLang);
                this.sidebarVisible = false;
               }

  ngOnInit(): void {
    const navbar: HTMLElement = this.element.nativeElement;
    this.toggleButton = navbar.getElementsByClassName('navbar-toggler')[0];
    this.langVisible = this.validarResponsiveIdioma();

  }

  useLanguage(lang: string) {
    if (lang === 'es') {
      this.defaultSpanish = true;
    } else {
      this.defaultSpanish = false;
    }
    this.activeLang = lang;
    localStorage.setItem('lang', lang);
    this.translate.use(lang);
    this.router.navigate([], { queryParams: { lang: localStorage.getItem('lang') } });
  }

  validarResponsiveIdioma() {
    return (screen.width > 991) ? false : true;
  }

  sidebarOpen() {
    const toggleButton = this.toggleButton;
    const html = document.getElementsByTagName('html')[0];
    setTimeout(() => {
      toggleButton.classList.add('toggled');
    }, 500);
    html.classList.add('nav-open');

    this.sidebarVisible = true;
  }

  sidebarClose() {
    const html = document.getElementsByTagName('html')[0];
    this.toggleButton.classList.remove('toggled');
    this.sidebarVisible = false;
    html.classList.remove('nav-open');
  }
  sidebarToggle() {
    if (this.sidebarVisible === false) {
      this.sidebarOpen();
    } else {
      this.sidebarClose();
    }
  }
  isHome() {
    let titlee = this.location.prepareExternalUrl(this.location.path());
    if (titlee.charAt(0) === '#') {
      titlee = titlee.slice(1);
    }
    if (titlee === '/home') {
      return true;
    }
    else {
      return false;
    }
  }
  isDocumentation() {
    let titlee = this.location.prepareExternalUrl(this.location.path());
    if (titlee.charAt(0) === '#') {
      titlee = titlee.slice(1);
    }
    if (titlee === '/documentation') {
      return true;
    }
    else {
      return false;
    }
  }
}
