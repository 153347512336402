<div class="wrapper banner-home">
  <div class="page-header section-dark background-latinAmerica">
    <div class="filter"></div>
    <div class="content-center">
      <div class="container">
        <div class="d-flex justify-content-between flex-wrap align-content-center">
          <div class="col-12 col-sm-12 col-md-6 col-lg-6 h-100">
            <div class="title-brand mt-5 mb-3">
              <h1 class="banner-title">
                {{'latin-america' | translate}}
              </h1>
            </div>
          </div>
          <div class="col-12 col-sm-12 col-md-6 col-lg-6 h-100">
            <div class="title-brand-description mt-4">
              <h3 class="description mt-4 text-center">
                <span>{{'financiamientoclimatico' | translate}}</span>
              </h3>
              <h5>
                {{'financiamientoclimaticoLatinAmerica' | translate}}
              </h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container-fluid">
  <div class="section-latam">
    <div class="col-12">
      <h1 class="text-center">
        {{'a_strong_track' | translate}}
      </h1>
      <div class="section-two-text">
        <p>
          {{'a_strong_track_p1' | translate}}
        </p>
        <p>
          {{'a_strong_track_p2' | translate}}
        </p>

        <ul class="list-items-sect">
          <li [innerHTML]="'a_strong_track_item1' | translate"></li>
          <li [innerHTML]="'a_strong_track_item2' | translate"></li>
        </ul>
      </div>
    </div>
  </div>
</div>

<div class="back-gray">
  <div class="container-project-la pt-3 pb-4">
    <app-card-projects [region]="'Latino América'"></app-card-projects>
  </div>
</div>

<div class="container-fluid">
  <div class="section-tree-text">
    <div class="col-12">
      <h1 class="text-left">
        {{'financial_partners' | translate}}
      </h1>
      <div class="content-text">
        <p>
          {{'financial_partners_1' | translate}}
        </p>
        <p>
          {{'financial_partners_2' | translate}}
        </p>
        <p>
          {{'financial_partners_3' | translate}}
        </p>
        <p>
          {{'financial_partners_4' | translate}}
        </p>
      </div>
    </div>
  </div>
</div>

<div class="container-fluid back-gray">
  <div class="col-12">
    <h1 class="title-climate">
      {{'climate_finance' | translate}}
    </h1>
  </div>
  <div class="section-info-img d-flex flex-wrap align-content-center justify-content-center pb-5">
    <img class="img-fluid mt-5" src="../../../assets/images/info-problems.png" alt="Problems">
  </div>

</div>

<app-countrie-flags [region]="'Latino América'"></app-countrie-flags>

<div class="container-fluid back-gray">
  <div class="section-six">
    <div class="col-12">
      <h1 class="text-center">
        {{'donors' | translate}}
      </h1>
      <p class="text-center">
        {{'donors_parrafo' | translate}}
      </p>
    </div>

    <div class="section-donors d-flex flex-wrap align-content-center justify-content-center card" data-aos="fade-zoom-in"
    data-aos-easing="ease-in-back"
    data-aos-delay="300"
    data-aos-offset="0">
      <a [href]="donorsData[1].link" target="_blank">
        <div class="row">
          <div class="col-12 col-md-3 d-flex flex-wrap align-content-center justify-content-center">
            <img class="img-fluid" [src]="donorsData[1].image" alt="">
          </div>
          <div class="col-12 col-md-9 section-description">
            {{'descripcion_ndf' | translate}}
          </div>
        </div>
      </a>
    </div>
    <div class="section-donors d-flex flex-wrap align-content-center justify-content-center card" data-aos="fade-zoom-in"
    data-aos-easing="ease-in-back"
    data-aos-delay="300"
    data-aos-offset="0">
      <a [href]="donorsData[2].link" target="_blank">
        <div class="row">
          <div class="col-12 col-md-3 d-flex flex-wrap align-content-center justify-content-center">
            <img class="img-fluid" [src]="donorsData[2].image" alt="">
          </div>
          <div class="col-12 col-md-9 section-description">
            {{'descripcion_bidlab' | translate}}
          </div>
        </div>
      </a>
    </div>

  </div>

</div>

<app-footer></app-footer>
