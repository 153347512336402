<div class="wrapper banner-home">
  <div class="page-header section-dark background-latinAmerica">
    <div class="filter"></div>
    <div class="content-center">
      <div class="container">

        <div class="d-flex justify-content-between flex-wrap align-content-center">
          <div class="col-12 col-sm-12 col-md-6 col-lg-6 h-100">
            <div class="title-brand mt-5 mb-3">
              <h1 class="banner-title">
                {{'caribbean' | translate}}
              </h1>
            </div>
          </div>
          <div class="col-12 col-sm-12 col-md-6 col-lg-6 h-100">
            <div class="title-brand-description mt-4">
              <h3 class="description mt-4 text-center">
                <span>
                  {{'financiamientoclimatico' | translate}}
                </span>
              </h3>
              <h5>
                {{'financiamientoclimaticoCaribbean' | translate}}
              </h5>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>

<div class="container-fluid">
  <div class="section-two-text container-home">
    <div class="row">
      <div class="col-12">
        <h2 class="title-green text-uppercase">{{'building-resilience' | translate}}</h2>
      </div>
      <div class="col-12">
        <div class="sect-text" innerHTML="{{'building-text' | translate}}">
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container-fluid back-gray">
  <div class="section-one-text section-w section-title">
    <div class="row">
      <div class="col-12 col-md-12">
        <div class="row ">
          <div class="col-12">
            <h2>{{'titleEcomicroPilotProjects' | translate}}</h2>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-12">
        <div class="sect-text">
          <p>
            {{'descripcionEcomicroPilotProjects' | translate}}
          </p>
        </div>
        <div class="row d-flex flex-wrap align-content-center justify-content-center">
          <div class="col-12 col-md-3 center-element" data-aos="fade-zoom-in"
          data-aos-easing="ease-in-back"
          data-aos-delay="300"
          data-aos-offset="0">
            <div class="card card-profile">
              <div class="card-avatar">
                <div class="image-finance">
                  <img class="img img-fluid" src="../../../assets/images/caribbean-icons/note-pen.png" alt="Notes Icon">
                </div>
              </div>
              <h4 class="card-title">{{'componente' | translate}} 1:</h4>
              <div class="card-body">
                <p class="card-description ">
                  {{'descripcionComponente1' | translate}}
                  
                </p>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-3 center-element" data-aos="fade-zoom-in"
          data-aos-easing="ease-in-back"
          data-aos-delay="300"
          data-aos-offset="0">
            <div class="card card-profile">
              <div class="card-avatar">
                <div class="image-finance">
                  <img class="img img-fluid" src="../../../assets/images/caribbean-icons/graph.png" alt="Graph Icon">
                </div>
              </div>
              <h4 class="card-title">{{'componente' | translate}} 2:</h4>
              <div class="card-body">
                <p class="card-description ">
                  {{'descripcionComponente2' | translate}}
                </p>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-3 center-element" data-aos="fade-zoom-in"
          data-aos-easing="ease-in-back"
          data-aos-delay="300"
          data-aos-offset="0">
            <div class="card card-profile">
              <div class="card-avatar">
                <div class="image-finance">
                  <img class="img img-fluid" src="../../../assets/images/caribbean-icons/bulb.png" alt="Bulb Icon">
                </div>
              </div>
              <h4 class="card-title">{{'componente' | translate}} 3:</h4>
              <div class="card-body">
                <p class="card-description">
                  {{'descripcionComponente3' | translate}}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Componente 1 -->
<div class="container-fluid">
  <div class="section-two-text section-w section-title">
    <div class="row">
      <div class="col-12 col-md-3 d-flex flex-wrap align-content-center justify-content-center">
        <div class="row">
          <div class="col-12">
            <img class="img img-fluid image-components" src="../../../assets/images/caribbean-icons/note-pen.png"
              alt="Notes Icon" data-aos="fade-right">
          </div>
        </div>
      </div>
      <div class="col-12 col-md-9 sect-text">
        <h2 innerHTML="{{'tituloArticulo1' | translate}}"></h2>
        <div class="mt-3" innerHTML="{{'contenidoArticulo1' | translate}}"></div> 
      </div>
    </div>
  </div>
</div>

<!-- 3 areas -->
<div class="container-fluid back-gray">
  <div class="section-three-text section-w section-title">
    <div class="row">
      <div class="col-12 col-md-12 pb-5">
        <div class="row ">
          <div class="col-12">
            <h2>{{'tresfinanzasverdes' | translate}}</h2>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-12">
        <div class="row d-flex flex-wrap align-content-center justify-content-center">
          <div class="col-12 col-md-4 mb-5">
            <div class="center-element">
              <div class="icon-section">
                <img class="img img-fluid" src="../../../assets/images/iconos-ecomicro/grupo-6079.png"
                  alt="Solar Panel">
              </div>
            </div>
            <div class="center-element">
              <h3 innerHTML="{{'permiteaccesoenergiaCARIBE' | translate}}">
              </h3>
            </div>
            <p>
              {{'item1EnablingAccess' | translate}}
            </p>
          </div>
          <div class="col-12 col-md-4 mb-5">
            <div class="center-element">
              <div class="icon-section">
                <img class="img img-fluid" src="../../../assets/images/iconos-ecomicro/grupo-5998.png" alt="Bulb">
              </div>
            </div>
            <div class="center-element">
              <h3 innerHTML="{{'facilitandoenergeticaCARIBE' | translate}}">
              </h3>
            </div>
            <p>
              {{'item2FacilitingEnergy' | translate}}
            </p>
          </div>
          <div class="col-12 col-md-4 mb-5">
            <div class="center-element">
              <div class="icon-section">
                <img class="img img-fluid" src="../../../assets/images/iconos-ecomicro/grupo-5999.png" alt="Cloud">
              </div>
            </div>
            <div class="center-element">
              <h3 innerHTML="{{'promocionadaptacionclimaticoCARIBE' | translate}}">
              </h3>
            </div>
            <p>
              {{'item3PromotingAdaptation' | translate}}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container-fluid">
  <div class="section-four-text section-w section-title">
    <div class="row">
      <div class="col-12 col-md-9 sect-text">
        <h2 innerHTML="{{'tituloArticulo2' | translate}}"></h2>
        <p>
          {{'contenidoArticulo2' | translate}}
        </p>
      </div>
      <div class="col-12 col-md-3 d-flex flex-wrap align-content-center justify-content-center">
        <div class="row">
          <div class="col-12">
            <img *ngIf="sizeWidth >= 767" class="img img-fluid image-components" src="../../../assets/images/caribbean-icons/graph.png"
              alt="Graph Icon" data-aos="fade-left">
            <img *ngIf="sizeWidth < 767" class="img img-fluid image-components" src="../../../assets/images/caribbean-icons/graph.png"
              alt="Graph Icon" data-aos="fade-up">
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container-fluid back-gray">
  <div class="section-five-text section-w section-title">
    <div class="row">
      <div class="col-12 col-md-3 d-flex flex-wrap align-content-center justify-content-center">
        <div class="row">
          <div class="col-12">
            <img class="img img-fluid image-components" src="../../../assets/images/caribbean-icons/bulb.png"
              alt="Bulb" data-aos="fade-right">
          </div>
        </div>
      </div>
      <div class="col-12 col-md-9 sect-text">
        <h2 innerHTML="{{'tituloArticulo3' | translate}}"></h2>
        <p>
        <p>
          {{'contenidoArticulo3' | translate}}
        </p>
      </div>
    </div>
  </div>
</div>

<div class="container-fluid">
  <div class="section-six-text section-w section-title">
    <div class="row">
      <div class="col-12 col-md-12">
        <div class="row ">
          <div class="col-12">
            <h2>{{'nuestroaliados' | translate}}</h2>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-12">
        <div class="sect-text">
          <p>
            
            {{'descripcionNuestrosAliadosCaribe' | translate}}
          </p>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="back-gray">
  <app-countrie-flags [region]="'Caribe'"></app-countrie-flags>
</div>

<div class="container-fluid">
  <div class="section-six">
    <div class="col-12">
      <h1 class="text-center">
        {{'donors' | translate}}
      </h1>
      <p class="text-center">
        {{'donors_parrafo' | translate}}
      </p>
    </div>

    <div class="section-donors d-flex flex-wrap align-content-center justify-content-center card" data-aos="fade-zoom-in"
    data-aos-easing="ease-in-back"
    data-aos-delay="300"
    data-aos-offset="0">
      <a [href]="donorsData[0].link" target="_blank">
        <div class="row">
          <div class="col-12 col-md-3 d-flex flex-wrap align-content-center justify-content-center">
            <img class="img-fluid" [src]="donorsData[0].image" alt="">
          </div>
          <div class="col-12 col-md-9 section-description" innerHTML="{{'descripcion_img_caribbean' | translate}}"></div>
        </div>
      </a>
    </div>
  </div>
</div>

<app-footer></app-footer>
