import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { ProjectsService } from 'src/app/core/services/projects.service';
import { CountryService } from 'src/app/core/services/country.service';

@Component({
  selector: 'app-card-projects',
  templateUrl: './card-projects.component.html',
  styleUrls: ['./card-projects.component.scss']
})
export class CardProjectsComponent implements OnInit {

  @Input() region: string;
  @Input() projectData: any[];
  @Input() viewInternal: boolean = false;

  countrieCaribbean: any[] = [];
  countrieLatinAmerica: any[] = [];
  open = false;
  projectsData: any[] = [];
  dataOpen = false;
  language = '';
  lang = 'en';

  customOptionOne: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: true,
    nav: false,
    navSpeed: 700,
    autoplay:true,
    autoplayTimeout:3000,
    autoplayHoverPause:true,
    dotsSpeed: 3000,
    navText: ['', ''],
    responsive: {
      0: { items: 1, },
      400: { items: 1, },
      740: { items: 2, },
      940: { items: 3, },
      1680: { items: 3, },
      1920: { items: 3, }
    },
  };

  constructor(private router: Router,
    private projectsService: ProjectsService,
    private countryService: CountryService) {
    this.router.events.subscribe((event: any) => {
      if (typeof event.url !== 'undefined') {
        if (event.url.includes('lang')) {
          this.ngOnInit();
        }
      }
    });
  }

  ngOnInit(): void {
    this.lang = localStorage.getItem('lang');
    this.language = (this.lang === "en") ? 'EN' : 'ES';
    this.getProjectsRegions();
  }

  getImagesJson(arr:Array<any>, id:any) {
    let finalimage;
    for (let i = 0; i < arr.length; i++) {
      const element = arr[i];
      if (id === element.id) {
        finalimage = element.attributes.uri.url
      }
    }
    return finalimage;
  }
  
  // getProjectsRegions() {
  //   this.projectsService.getProjectsJson().subscribe( (res:any) => {
  //     let projEN = [];
  //     let projES = [];
  //     for (let i = 0; i < res.data.length; i++) {
  //       const project = res.data[i];
  //       if (project.attributes.langcode === 'en') {
  //         this.projectsService.getIconsProectsJson().subscribe( (x:any) => {
  //           for (let i = 0; i < x.data.length; i++) {
  //             const tax = x.data[i];
  //             this.countryService.getCountriesJson().subscribe( (co:any) => {
  //               for (let i = 0; i < co.data.length; i++) {
  //                 const country = co.data[i];
  //                 if (project.relationships.field_icono.data.id === tax.id) {
  //                   if (country.id === project.relationships.field_pais.data.id) {                      
  //                     projEN.push({
  //                       id: project.id,
  //                       imageProject: `https://dev-ecomicro.pantheonsite.io${this.getImagesJson(res.included, project.relationships.field_imagen_proyecto.data.id)}`,
  //                       title: project.attributes.title,
  //                       descriptionProj: project.attributes.field_descripcion_proyecto,
  //                       projectType: project.attributes.field_tipo_proyecto,
  //                       projectIcon: `https://dev-ecomicro.pantheonsite.io${this.getImagesJson(x.included, tax.relationships.field_imagen_icono.data.id)}`,
  //                       countryImage: `https://dev-ecomicro.pantheonsite.io${this.getImagesJson(co.included, country.relationships.field_bandera.data.id)}`,
  //                       countryTitle: country.attributes.title,
  //                       projectVideo: country.attributes.field_video_proyecto
  //                     });
  //                   }
  //                 }
  //               }
  //             });
  //           }
  //         });
  //       } else if (project.attributes.langcode === 'es') {
  //         this.projectsService.getIconsProectsJson().subscribe( (x:any) => {
  //           for (let i = 0; i < x.data.length; i++) {
  //             const tax = x.data[i];
  //             this.countryService.getCountriesJson().subscribe( (co:any) => {
  //               for (let i = 0; i < co.data.length; i++) {
  //                 const country = co.data[i];
  //                 if (project.relationships.field_icono.data.id === tax.id) {
  //                   if (country.id === project.relationships.field_pais.data.id) {                      
  //                     projES.push({
  //                       id: project.id,
  //                       imageProject: `https://dev-ecomicro.pantheonsite.io${this.getImagesJson(res.included, project.relationships.field_imagen_proyecto.data.id)}`,
  //                       title: project.attributes.title,
  //                       descriptionProj: project.attributes.field_descripcion_proyecto.summary,
  //                       projectType: project.attributes.field_tipo_proyecto,
  //                       projectIcon: `https://dev-ecomicro.pantheonsite.io${this.getImagesJson(x.included, tax.relationships.field_imagen_icono.data.id)}`,
  //                       countryImage: `https://dev-ecomicro.pantheonsite.io${this.getImagesJson(co.included, country.relationships.field_bandera.data.id)}`,
  //                       countryTitle: country.attributes.title,
  //                       projectVideo: country.attributes.field_video_proyecto
  //                     });
  //                   }
  //                 }
  //               }
  //             });
  //           }
  //         });
  //       }
  //     }

  //     this.dataOpen = true;
  //     this.projectsData = [];

  //     if (this.language === 'EN') {
  //       this.projectsData = projEN;
  //     } else {
  //       this.projectsData = projES;
  //     } 
  //   });
        
  //   if (this.viewInternal) this.projectsData = this.projectsData.filter((x) => x.id !== this.projectData['id']);
  //   this.filterCountrie(this.region);
  // }

  async getProjectsRegions () {
    let projEN = [];
    let projES = [];
    const projects = await this.projectsService.getProjectsPromise();
    const icons = await this.projectsService.getIconsPromise();
    const countries = await this.countryService.getCountriesPromise();

    for (let i = 0; i < projects.data.length; i++) {
      const proj = projects.data[i];
      if (proj.attributes.langcode === 'en') {
        for (let i = 0; i < icons.data.length; i++) {
          const icon = icons.data[i];
          for (let i = 0; i < countries.data.length; i++) {
            const co = countries.data[i];
            if (proj.relationships.field_icono.data.id === icon.id) {
              if (co.id === proj.relationships.field_pais.data.id) {
                projEN.push({
                  id: proj.id,
                  imageProject: `https://dev-ecomicro.pantheonsite.io${this.getImagesJson(projects.included, proj.relationships.field_imagen_proyecto.data.id)}`,
                  title: proj.attributes.title,
                  descriptionProj: proj.attributes.field_descripcion_proyecto,
                  projectType: proj.attributes.field_tipo_proyecto,
                  projectIcon: `https://dev-ecomicro.pantheonsite.io${this.getImagesJson(icons.included, icon.relationships.field_imagen_icono.data.id)}`,
                  countryImage: `https://dev-ecomicro.pantheonsite.io${this.getImagesJson(countries.included, co.relationships.field_bandera.data.id)}`,
                  countryTitle: co.attributes.title,
                  projectVideo: co.attributes.field_video_proyecto
                });
              }
            }
            
          }
        }
      } else if (proj.attributes.langcode === 'es') {
        for (let i = 0; i < icons.data.length; i++) {
          const icon = icons.data[i];
          for (let i = 0; i < countries.data.length; i++) {
            const co = countries.data[i];
            if (proj.relationships.field_icono.data.id === icon.id) {
              if (co.id === proj.relationships.field_pais.data.id) {
                projES.push({
                  id: proj.id,
                  imageProject: `https://dev-ecomicro.pantheonsite.io${this.getImagesJson(projects.included, proj.relationships.field_imagen_proyecto.data.id)}`,
                  title: proj.attributes.title,
                  descriptionProj: proj.attributes.field_descripcion_proyecto,
                  projectType: proj.attributes.field_tipo_proyecto,
                  projectIcon: `https://dev-ecomicro.pantheonsite.io${this.getImagesJson(icons.included, icon.relationships.field_imagen_icono.data.id)}`,
                  countryImage: `https://dev-ecomicro.pantheonsite.io${this.getImagesJson(countries.included, co.relationships.field_bandera.data.id)}`,
                  countryTitle: co.attributes.title,
                  projectVideo: co.attributes.field_video_proyecto
                });
              }
            }
          }
        }
      }
    }

    if (this.language === 'EN') {
      this.projectsData = projEN;
    } else {
      this.projectsData = projES;
    }

    if (this.viewInternal) this.projectsData = this.projectsData.filter((x) => x.id !== this.projectData['id']);
    this.filterCountrie(this.region);
  }

  filterCountrie(regionPage: string) {
    if (this.projectsData && this.projectsData.length > 0) {
      this.countrieLatinAmerica = this.projectsData.filter(region => region.fieldPais.entity.fieldRegion === regionPage);
      if (this.countrieLatinAmerica.length > 0 && this.region === regionPage) {
        this.projectsData = this.countrieLatinAmerica;
      }
    }
  }

  content(project: any): any {
    this.router.navigate([`/project/${project.id}`]);
    window.scroll(0,300);
    this.projectsService.singleProject = project
  }
}
