<div class="container-fluid">
  <div class="section-flags">
    <div class="row">
      <div class="col-12 col-md-12">
        <div class="row">
          <div class="col-12">
            <h2>{{'COUNTRIES_TITLE' | translate}}</h2>
          </div>
        </div>

        <div class="col-12 col-md-12">
          <div class="row">
            <div class="col-lg-3 col-md-4 col-sm-6 col-6 px-md-3 p-sm-5 mx-auto" *ngFor="let countrie of countriesFlagsJson; let i = index">
              <div class="sect-img d-flex flex-wrap align-content-center justify-content-center">
                <img class="img-fluid" [src]="countrie.countryFlag" alt="country" data-aos="fade-up">
              </div>
              <h3>
                {{ countrie.countryTitle }}
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
