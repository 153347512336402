import { Component, OnInit, Renderer2, ElementRef, ViewChild } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Location } from '@angular/common';
import { filter } from 'rxjs/operators';
import { NavMenuComponent } from 'src/app/shared/components/nav-menu/nav-menu.component';


@Component({
  selector: 'app-ecomicro',
  templateUrl: './ecomicro.component.html',
  styleUrls: ['./ecomicro.component.scss']
})
export class EcomicroComponent implements OnInit {
  @ViewChild(NavMenuComponent) navbar: NavMenuComponent;

  constructor(private renderer: Renderer2,
    private router: Router,
    private element: ElementRef,
    public location: Location) { }

  ngOnInit(): void {
    let navbar = (document.querySelector('.navbar[color-on-scroll]') as HTMLElement);
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      if (window.outerWidth > 991) {
        window.document.children[0].scrollTop = 0;
      } else {
        window.document.activeElement.scrollTop = 0;
      }
      this.navbar.sidebarClose();
    });

    this.renderer.listen('window', 'scroll', (event) => {
      const number = window.scrollY;
      if (number > 90 || window.pageYOffset > 90) {
        // add logic
        navbar.classList.remove('navbar-transparent');
        this.navbar.langVisible = true;
      } else {
        // remove logic
        navbar.classList.add('navbar-transparent');
        this.navbar.langVisible = false;
      }
    });
  }

}
