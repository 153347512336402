import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import {
  NgxUiLoaderModule,
  NgxUiLoaderConfig,
  SPINNER,
  POSITION,
  PB_DIRECTION
} from 'ngx-ui-loader';
const NGX_UI_LOADER_CONFIG: NgxUiLoaderConfig = {
  bgsColor: 'red',
  bgsOpacity: 0.5,
  bgsPosition: POSITION.bottomCenter,
  bgsSize: 60,
  fgsSize: 100,
  bgsType: SPINNER.rectangleBounce,
  pbDirection: PB_DIRECTION.leftToRight,
  pbThickness: 2,
  fgsType: 'chasing-dots',
  fgsColor: '#f9f9f9',
  blur: 15,
  pbColor: '#f9f9f9',
  overlayColor: '#409043f0'
};


// Bookstores
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { EllipsisModule } from 'ngx-ellipsis';
import { RouterModule } from '@angular/router';
import { environment } from 'src/environments/environment';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ToastrModule } from 'ngx-toastr';

// Kendo
import { InputsModule } from '@progress/kendo-angular-inputs';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { LabelModule } from '@progress/kendo-angular-label';


// Shared Components
import { HomeComponent } from './modules/home/home.component';
import { NavMenuComponent } from './shared/components/nav-menu/nav-menu.component';
import { EcomicroComponent } from './modules/ecomicro/ecomicro.component';
import { AppComponent } from './app.component';
import { NewsComponent } from './modules/news/news.component';
import { AboutComponent } from './modules/about/about.component';
import { LibraryComponent } from './modules/library/library.component';
import { ProjectsComponent } from './modules/projects/projects.component';
import { CaribbeanComponent } from './modules/caribbean/caribbean.component';
import { ContactUsComponent } from './modules/contact-us/contact-us.component';
import { LatinAmericaComponent } from './modules/latin-america/latin-america.component';
import { FooterComponent } from './shared/components/footer/footer.component';
import { CardNewsComponent } from './shared/components/card-news/card-news.component';
import { BarUpMarksComponent } from './shared/components/bar-up-marks/bar-up-marks.component';
import { CardLibraryComponent } from './shared/components/card-library/card-library.component';
import { BannerSliderComponent } from './shared/components/banner-slider/banner-slider.component';
import { CardProjectsComponent } from './shared/components/card-projects/card-projects.component';
import { CountrieFlagsComponent } from './shared/components/countrie-flags/countrie-flags.component';
import { CardAllProjectsComponent } from './shared/components/card-all-projects/card-all-projects.component';
import { CardBeneficiariesComponent } from './shared/components/card-beneficiaries/card-beneficiaries.component';
import { ProjectsContentComponent } from './shared/components/projects-content/projects-content.component';
import { BulletinComponent } from './modules/bulletin/bulletin.component';
import { ViewLibraryComponent } from './modules/view-library/view-library.component';

//GraphQL

import { GraphQLModule } from './graphql.module';

// Apollo
import { APOLLO_OPTIONS } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { InMemoryCache } from '@apollo/client/core';

//Pipes
import { DomSafePipe } from './core/pipes/dom-safe.pipe';
import { FormatDatePipe } from './core/pipes/format-date.pipe';
import { NgxCaptchaModule } from 'ngx-captcha';
import { ContadorAnimadoComponent } from './shared/components/contador-animado/contador-animado.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';


@NgModule({
  declarations: [
    AppComponent,
    EcomicroComponent,
    HomeComponent,
    NavMenuComponent,
    AboutComponent,
    ProjectsComponent,
    LatinAmericaComponent,
    CaribbeanComponent,
    LibraryComponent,
    NewsComponent,
    ContactUsComponent,
    BarUpMarksComponent,
    FooterComponent,
    CardNewsComponent,
    BannerSliderComponent,
    CardProjectsComponent,
    CountrieFlagsComponent,
    CardBeneficiariesComponent,
    CardLibraryComponent,
    CardAllProjectsComponent,
    ProjectsContentComponent,
    DomSafePipe,
    BulletinComponent,
    ViewLibraryComponent,
    FormatDatePipe,
    ContadorAnimadoComponent,
  ],
  imports: [
    ReactiveFormsModule,
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    InputsModule,
    DropDownsModule,
    LabelModule,
    NgbModule,
    FormsModule,
    CarouselModule,
    EllipsisModule,
    RouterModule,
    GraphQLModule,
    HttpClientModule,
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    NgxUiLoaderModule.forRoot(NGX_UI_LOADER_CONFIG),
    NgxCaptchaModule,
    InfiniteScrollModule,
    // AgmCoreModule.forRoot({
    //   apiKey: 'AIzaSyCHiGAmtuxIbVoUaej0PRZNX_7A8ThICps'
    // })
  ],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: (httpLink: HttpLink) => {
        return {
          cache: new InMemoryCache(),
          link: httpLink.create({
            uri: `${environment.urlGraph}/graphql`,
          }),
        };
      },
      deps: [HttpLink],
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

export function HttpLoaderFactory(http: HttpClient) {
  const urlDinamicPrd = environment.dictionaryUrl;

  return new TranslateHttpLoader(http, urlDinamicPrd, '.json');
}
