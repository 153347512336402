import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { donorsData } from 'src/app/core/mocks/data-news';
import { SeoService } from 'src/app/core/services/seo.service';

@Component({
  selector: 'app-latin-america',
  templateUrl: './latin-america.component.html',
  styleUrls: ['./latin-america.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LatinAmericaComponent implements OnInit {

  public projectsData: any[];
  public donorsData: any[] = donorsData;
  dataOpen = false;
  data;
  constructor(
    private seoService: SeoService,
    private title: Title,
  ) { }

  ngOnInit(): void {
    this.flags();
  }

  flags() {
    this.seoService.generateFlags({
      title: 'América Latina | Desde que EcoMicro inició, en 2011, ha implementado proyectos piloto en 11 países de América Latina',
      site_name: 'EcoMicro',
      description: 'Desde que EcoMicro inició, en 2011, ha implementado proyectos piloto en 11 países de América Latina, permitiendo a las MIPYMES, pequeños agricultores y hogares de bajos ingresos para obtener préstamos verdes.',
      slug: 'latin-america',
      image: 'https://ecomicro.azureedge.net/fondo-latinAmerica.f0d1dca1b29aef596638.png'
    });
    this.title.setTitle('América Latina | Desde que EcoMicro inició, en 2011, ha implementado proyectos piloto en 11 países de América Latina');
  }

}
