import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { NewsService } from 'src/app/core/services/news.service';

@Component({
  selector: 'app-bulletin',
  templateUrl: './bulletin.component.html',
  styleUrls: ['./bulletin.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class BulletinComponent implements OnInit {

  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: true,
    nav: false,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1
      },
      740: {
        items: 2
      },
      1180: {
        items: 3
      },
      1680: {
        items: 4
      }
    }
  }

  textSquares = [];
  showShortDesciption = true;
  language = '';
  lang = 'en';
  dataBulletin: {
    title: '',
    fieldTituloCuadrosTexto: '',
    fieldLinkBoletin: '',
    fieldCuadrosTexto: Array<any>,
    fieldDescripcionesBloque2: Array<any>,
    fieldDescripcionesBloque3: Array<any>,
    fieldDescripcionesBloque4: Array<any>,
    fieldImagenesBloque1: Array<any>,
    fieldImagenesBloque2: Array<any>,
    fieldImagenesBloque3: Array<any>,
    fieldVideosBloque1: Array<any>,
    fieldImagenPrincipal: {
      url: string
    },
    fieldDescripcionBoletin: {
      processed: '',
      summary: ''
    }
  };
  dataBulletinMedia = {
    img: [],
    video: [],
  }
  newProducts: any;
  width = 0;


  constructor(private newsService: NewsService, private router: Router, private routes: ActivatedRoute) {

    this.router.events.subscribe((event: any) => {
      if (typeof event.url !== 'undefined') {
        if (event.url.includes('lang')) {
          this.ngOnInit();
        }
      }
    });
  }

  ngOnInit(): void {
    this.lang = localStorage.getItem('lang');
    this.language = (this.lang === "en") ? 'EN' : 'ES';
    this.routes.params.subscribe(parametros => {
      // this.getBulletinID(parametros.id, this.language);
      this.getSingleNew(parametros.id);
    });
    this.width = window.innerWidth;
  }

  stylesBackgroundNews(urlImage: string|undefined) {
    return {'background-image': 'linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(' + urlImage + ')'}
  }

  // getBulletinID(id: string, language: string) {
  //   this.newsService.getBulletsId(id, language, 100, 0, (data, total) => {
  //     this.dataBulletin = data[0];
  //     if (this.dataBulletin.fieldImagenesBloque1) {
  //       for (const img of this.dataBulletin.fieldImagenesBloque1) {
  //         this.dataBulletinMedia.img.push(img);
  //       }
  //     }

  //     if (this.dataBulletin.fieldVideosBloque1) {
  //       for (const videoCon of this.dataBulletin.fieldVideosBloque1) {
  //         this.dataBulletinMedia.video.push(videoCon);
  //       }
  //     }
  //     this.processProducts(this.dataBulletin.fieldDescripcionesBloque2, this.dataBulletin.fieldImagenesBloque2);
  //   })
  // }

  getImagesJson(arr:Array<any>, id:any) {
    let finalimage;
    for (let i = 0; i < arr.length; i++) {
      const element = arr[i];
      if (id === element.id) {
        finalimage = element.attributes.uri.url
      }
    }
    return finalimage;
  }

  getBloqueImages(arrIncl, arrIds) {
    let finalArr = [];
    for (let i = 0; i < arrIds.data.length; i++) {
      const imgId = arrIds.data[i];
      for (let i = 0; i < arrIncl.length; i++) {
        const images = arrIncl[i];
        if (imgId.id === images.id) {
          finalArr.push(`https://dev-ecomicro.pantheonsite.io${images.attributes.uri.url}`)
        }
      }

    }
    return finalArr
  }

  async getSingleNew(letterID) {
    const newsRes = await this.newsService.getNewsPromise(letterID);
    for (let i = 0; i < newsRes.data.length; i++) {
      const newsDta = newsRes.data[i];      
      this.dataBulletin = {
        title: newsDta.attributes.title,
        fieldTituloCuadrosTexto: newsDta.attributes.field_titulo_cuadros_texto,
        fieldLinkBoletin: newsDta.attributes.field_link_boletin,
        fieldCuadrosTexto: newsDta.attributes.field_cuadros_texto,
        fieldDescripcionesBloque2: newsDta.attributes.field_descripciones_bloque2,
        fieldDescripcionesBloque3: newsDta.attributes.field_descripciones_bloque_3,
        fieldDescripcionesBloque4: null,
        fieldImagenesBloque1: this.getBloqueImages(newsRes.included, newsDta.relationships.field_imagenes_bloque1),
        fieldImagenesBloque2: this.getBloqueImages(newsRes.included, newsDta.relationships.field_imagenes_bloque2),
        fieldImagenesBloque3: this.getBloqueImages(newsRes.included, newsDta.relationships.field_imagenes_bloque3),
        fieldVideosBloque1: newsDta.attributes.field_videos_bloque1,
        fieldImagenPrincipal: {
          url: `https://dev-ecomicro.pantheonsite.io${this.getImagesJson(newsRes.included, newsDta.relationships.field_imagen_principal.data.id)}`
        },
        fieldDescripcionBoletin: {
          processed: newsDta.attributes.field_descripcion_boletin.processed,
          summary: newsDta.attributes.field_descripcion_boletin.summary
        }
      }

      if (this.dataBulletin.fieldImagenesBloque1) {
        for (let i = 0; i < this.dataBulletin.fieldImagenesBloque1.length; i++) {
          const bloq1 = this.dataBulletin.fieldImagenesBloque1[i];
          this.dataBulletinMedia.img.push(bloq1);
        }
      }

      if (this.dataBulletin.fieldVideosBloque1) {
        for (let i = 0; i < this.dataBulletin.fieldVideosBloque1.length; i++) {
          const vidBloq = this.dataBulletin.fieldVideosBloque1[i];
          this.dataBulletinMedia.video.push(vidBloq);
        }
      }

      this.processProducts(this.dataBulletin.fieldDescripcionesBloque2, this.dataBulletin.fieldImagenesBloque2);
      // console.log('from promise', this.dataBulletin);
    }
  }

  alterDescriptionText() {
    this.showShortDesciption = !this.showShortDesciption
  }

  validarVideo(url: string, type: string) {
    let typeUrl = url.includes('youtube');
    if (typeUrl && type === "youtube") {
      return true
    }

    if (!typeUrl && type === "server") {
      return true
    }
  }

  // Metodo para agrupar los textos & imagenes del bloque 2.
  processProducts(descripciones: Array<any>, imagenes: Array<any>) {
    this.newProducts = [];
    for (let i = 0; i < descripciones.length; i++) {
      const desc = descripciones[i];
      let product = {
        text: '',
        img: ''
      };

      product.text = desc.processed;

      if (imagenes[i]) product.img = imagenes[i];
      this.newProducts.push(product);
      // console.log('newProducts', this.newProducts);
      
    }
  }
}
